<template>
  <!--话务配置模块-->
  <div>
    <el-row :gutter="15">
      <el-col :span="24">

        <el-card shadow="never">
          <el-row :gutter="15">
            <el-col :span="12">
              <div class="top">
                <div>分配坐席</div>
                <div>
                  <el-button
                    style="margin-left: 20px;"
                    type="primary"
                    @click="add">添加
                  </el-button>
                </div>
<!--                <div style="margin-left: 20px">外显号码:</div>-->
<!--                <div>-->
<!--                  <el-input clearable style="" v-model="form.extrinsicNumber" placeholder="请输入"></el-input>-->
<!--                </div>-->
              </div>
              <div class="table_style">
                <my-table
                  ref="myTable"
                  :columns="columns"
                  :tableData="data"
                  :loading="loading"
                >
                  <template v-slot:calling="{ scope }">
                    <el-input clearable v-model.number="scope.row.calling" placeholder="请输入坐席号"></el-input>
                  </template>
                  <template v-slot:password="{ scope }">
                    <el-input clearable v-model="scope.row.password" show-password placeholder="请输入密码"></el-input>
                  </template>
<!--                  <template v-slot:status="{ scope }">-->
<!--                    <el-select v-model="scope.row.status" placeholder="请选择">-->
<!--                      <el-option-->
<!--                        v-for="item in options"-->
<!--                        :key="item.value"-->
<!--                        :label="item.label"-->
<!--                        :value="parseInt(item.value)">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </template>-->
                  <template v-slot:operationSlot="{ scope }">
                    <el-link
                      type="danger"
                      @click="delete_s( scope)"
                    >
                      删除
                    </el-link>
                  </template>
                </my-table>
              </div>
              <div class="save_style">
                <el-button
                  :loading="loadingbtn"
                  type="primary"
                  @click="save">保存
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>


    </el-row>

  </div>
</template>

<script>

// 权限
import { ShowTable } from "@/utils";

// 引入的接口
import {
  network_tel,
  network_tel_delete,
  network_tel_put
} from "@/api/yunli";

export default {
  props: {
    id: Number,
  },
  components: {

  },
  data() {
    return {
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      //加载动画
      loading:true,
      form:{
        extrinsicNumber:""
      },
      data:[],
      options: [{
        value: 0,
        label: '启用'
      }, {
        value: 1,
        label: '禁用'
      }],
      // 表头数据
      columns: [
        {
          label: "坐席号",
          prop: "calling",
          slot: "calling",
          isShow: true,
        },
        {
          label: "密码",
          prop: "password",
          slot: "password",
          isShow: true,
        },
        // {
        //   label: "状态",
        //   prop: "status",
        //   slot: "status",
        //   isShow: true,
        // },
        {
          label: "操作",
          width: "200",
          prop: "operationSlot",
          slot: "operationSlot",
          isShow: true,
        },
      ],

      //按钮状态
      loadingbtn:false
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
  },

  methods: {
    // 获取列表
    getList(){
      this.loading = true;
      network_tel(this.id).then(async (res) => {
        console.log(res);
        this.data = res.data;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },
    //添加--添加一行
    add(){
      let data = {
        id:0,
        calling:'',
        password:"",
        status:0
      }
      //push到表格 增加一行
      this.data.push(data)
    },
    //删除
    delete_s(scope){
      console.log(scope);
      //判断有没有id 如果没有就是假删除
      if(scope.row.id == 0){
        this.data.splice(scope.$index,1)
      }else {
        //有id 走下方接口进行删除
        network_tel_delete(this.id,scope.row.id).then(res => {
          if(res.code ==  200){
            this.$message.success(res.msg);
            this.getList();
          }else {
            this.$message.error(res.msg);
          }
        })
      }
    },
    //修改
    save(){
      this.loadingbtn = true;
      network_tel_put(this.id,{
        config: this.data
      }).then(async (res) => {
        console.log(res);
        if(res.code == 200){
          this.$message.success(res.msg);
          this.loadingbtn = false;
          this.getList();
        }else {
          this.$message.error(res.msg);
          this.loadingbtn = false;
        }
      }).catch((err) => {
        this.$message.error(err.msg);
        this.loadingbtn = false;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
  .top{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .input_style{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .table_style{
    margin: 20px;
  }
  .save_style{
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
