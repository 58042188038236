<template>
  <!--服务商列表详情模块-->
  <div>
    <div v-if="systemSettingsshow == false">
      <div class="title">
        <div class="title_t">
          <span>服务商详情</span>
        </div>
        <div class="title_r" @click="returnPage">
          <span>返回</span>
        </div>
      </div>

      <!--选项-->
      <div class="xuanxiang">
        <div
          class="xuanxiang_k"
          v-for="(item, index) in activeList"
          :key="index"
          :class="{ xuanxiang_k1: index == isShow }"
          @click="xuanxiangclick(item, index)"
        >
          <!-- <img :src="item.img" alt="" />-->
          <span>{{ item.name }}</span>
        </div>
      </div>

      <!--基本信息 模块-->
      <div v-if="isShow == 0">
        <el-form
          ref="form"
          label-position="left"
          :model="form"
          :rules="rules"
          label-width="160px"
        >
          <!--认证信息-->
          <el-card shadow="never">
            <el-row :gutter="15" style="margin-top: 20px">
              <el-col :span="4">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload"
                  :limit="1"
                  :file-list="fileList"
                  :class="{ hide_box: businessLicenseupload_btn }"
                  :on-change="businessLicensechange"
                  list-type="picture-card"
                  :on-preview="businessLicensePreview"
                  :on-remove="businessLicensehandleRemove"
                >
                  <i class="el-icon-plus"></i>
                  <div>上传营业执照</div>
                </el-upload>
                <el-dialog :visible.sync="businessLicensedialogVisible">
                  <img
                    width="100%"
                    :src="businessLicensedialogImageUrl"
                    alt=""
                  />
                </el-dialog>
              </el-col>
              <el-col :span="5">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload3"
                  :limit="1"
                  :file-list="fileList3"
                  :class="{ hide_box: logoupload_btn }"
                  :on-change="logochange"
                  list-type="picture-card"
                  :on-preview="logoPreview"
                  :on-remove="logohandleRemove"
                >
                  <i class="el-icon-plus"></i>
                  <div
                    style="
                      font-size: 16px;
                      font-weight: 500;
                      color: #ff9b05;
                      margin-top: 15px;
                    "
                  >
                    上传logo
                  </div>
                </el-upload>
                <el-dialog :visible.sync="logodialogVisible">
                  <img width="100%" :src="logodialogImageUrl" alt="" />
                </el-dialog>
              </el-col>

              <el-col :span="15">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                  "
                >
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="
                      font-size: 20px;
                      font-weight: 700;
                      color: #202033;
                      margin-right: 20px;
                    "
                    >认证信息</span
                  >
                  <!-- <img v-if="form.approve_status == 1" src="@/assets/images/home/yunli/yirenzheng.png" alt="" style="width: 96px;height: 31px;"> -->
                </div>
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="企业名称" prop="company_name">
                      <el-input
                        clearable
                        v-model="form.company_name"
                        placeholder="请输入企业名称"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="统一社会信用代码" prop="credit_code">
                      <el-input
                        clearable
                        v-model="form.credit_code"
                        placeholder="请输入信用代码"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="企业注册日期" prop="registration_date">
                      <el-date-picker
                        v-model="form.registration_date"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="注册资本" prop="registered_capital">
                      <el-input
                        clearable
                        v-model="form.registered_capital"
                        placeholder="请输入注册资本 "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- <el-row :gutter="15">

                </el-row> -->
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="省市区" prop="area_city_code">
                      <el-row :gutter="24">
                        <el-cascader
                          ref="refCascader"
                          v-model="form.area_city_code"
                          :options="city"
                          @change="citychange"
                          clearable
                        ></el-cascader>
                      </el-row>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="注册地址" prop="detailed_address">
                      <el-row :gutter="15">
                        <div style="display: flex; align-items: center">
                          <!--<el-cascader-->
                          <!--    ref="refCascader"-->
                          <!--    :options="city"-->
                          <!--    v-model="form.registration_place"></el-cascader>-->
                          <!--<el-input-->
                          <!--    clearable-->
                          <!--    v-model="form.detailed_address"-->
                          <!--    placeholder="请输入详细地址"/>-->
                          <div
                            class="xuanzedidianyangshi"
                            @click="opensiteselection(0)"
                          >
                            <span v-if="form.detailed_address == ''"
                              >请选择地址</span
                            >
                            <el-tooltip
                              class="item"
                              effect="dark"
                              :content="form.detailed_address"
                              placement="top-start"
                            >
                              <div class="didian">
                                {{ form.detailed_address }}
                              </div>
                            </el-tooltip>
                            <i class="el-icon-location-information"></i>
                          </div>
                        </div>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="法定代表人" prop="legal_person_name">
                      <el-input
                        clearable
                        v-model="form.legal_person_name"
                        placeholder="请输入法人姓名"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                      label="法定代表人身份证"
                      prop="legal_person_identity_number"
                    >
                      <el-input
                        clearable
                        v-model="form.legal_person_identity_number"
                        placeholder="请输入法人身份证 "
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="认证状态" prop="approve_status">
                      <el-select
                        v-model="form.approve_status"
                        placeholder="请选择认证状态"
                      >
                        <el-option label="未通过" :value="-1"></el-option>
                        <el-option label="待认证" :value="0"></el-option>
                        <el-option label="认证通过" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="坐标">
                      <div class="flex">
                        <div class="flex">
                          <el-input
                            clearable
                            v-model="form.longitude"
                            placeholder="请输入经度"
                        /></div>
                        <div class="flex" style="margin-left: 10px"
                          ><el-input
                            clearable
                            v-model="form.latitude"
                            placeholder="请输入维度"
                        /></div>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="15">
              <el-col :span="4">
                <!-- label="法人身份证正面" -->
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload1"
                  :limit="1"
                  :file-list="fileList1"
                  :class="{ hide_box: upload_btn }"
                  :on-change="change"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                >
                  <i class="el-icon-plus"></i>
                  <div
                    style="font-size: 12px; font-weight: 500; color: #ff9b05"
                  >
                    上传身份证正面
                  </div>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </el-col>
              <el-col :span="5">
                <el-upload
                  :action="action"
                  :headers="headers"
                  :on-success="successUpload2"
                  :limit="1"
                  :file-list="fileList2"
                  :class="{ hide_box: oppositeupload_btn }"
                  :on-change="oppositechange"
                  list-type="picture-card"
                  :on-preview="oppositePreview"
                  :on-remove="oppositehandleRemove"
                >
                  <i class="el-icon-plus"></i>
                  <div
                    style="font-size: 12px; font-weight: 500; color: #ff9b05"
                  >
                    上传身份证反面
                  </div>
                </el-upload>
                <el-dialog :visible.sync="oppositedialogVisible">
                  <img width="100%" :src="oppositedialogImageUrl" alt="" />
                </el-dialog>
              </el-col>
              <el-col :span="15">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                  "
                >
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="
                      font-size: 20px;
                      font-weight: 700;
                      color: #202033;
                      margin-right: 20px;
                    "
                    >企业基本信息</span
                  >
                  <!-- <img v-if="form.approve_status == 1" src="@/assets/images/home/yunli/yirenzheng.png" alt="" style="width: 96px;height: 31px;"> -->
                </div>
                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item
                      label="企业简称"
                      prop="facilitator_abbreviation"
                    >
                      <el-input
                        clearable
                        v-model="form.facilitator_abbreviation"
                        placeholder="请输入企业简称"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="企业客服电话" prop="service_phone">
                      <el-input
                        clearable
                        v-model="form.service_phone"
                        placeholder="请输入企业客服电话"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="调度负责人" prop="dispatch_name">
                      <el-input
                        clearable
                        v-model="form.dispatch_name"
                        placeholder="请输入调度负责人"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="调度负责人电话" prop="dispatch_phone">
                      <el-input
                        clearable
                        v-model="form.dispatch_phone"
                        placeholder="请输入调度负责人电话"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <div>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      margin-bottom: 20px;
                    "
                  >
                    <span
                      style="
                        width: 5px;
                        height: 19px;
                        background: #ff9b05;
                        display: inline-block;
                        margin-right: 10px;
                      "
                    ></span>
                    <span
                      style="
                        font-size: 20px;
                        font-weight: 700;
                        color: #202033;
                        margin-right: 20px;
                      "
                      >企业收款信息</span
                    >
                  </div>

                  <el-row :gutter="15">
                    <el-col :span="12">
                      <el-form-item
                        label="账户名称"
                        prop="collection_account_name"
                      >
                        <el-input
                          clearable
                          v-model="form.collection_account_name"
                          placeholder="请输入账户名称"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="收款账号"
                        prop="collection_account_number"
                      >
                        <el-input
                          clearable
                          v-model="form.collection_account_number"
                          placeholder="请输入收款账号"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15">
                    <el-col :span="12">
                      <el-form-item
                        label="开户行"
                        prop="collection_opening_bank"
                      >
                        <el-input
                          clearable
                          v-model="form.collection_opening_bank"
                          placeholder="请输入开户行"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="收款账户联行号"
                        prop="collection_bank_code"
                      >
                        <el-input
                          clearable
                          v-model="form.collection_bank_code"
                          placeholder="收款账户联行号"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>

                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                  "
                >
                  <span
                    style="
                      width: 5px;
                      height: 19px;
                      background: #ff9b05;
                      display: inline-block;
                      margin-right: 10px;
                    "
                  ></span>
                  <span
                    style="
                      font-size: 20px;
                      font-weight: 700;
                      color: #202033;
                      margin-right: 20px;
                    "
                    >账号信息</span
                  >
                </div>

                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="系统管理员" prop="account_name">
                      <el-input
                        clearable
                        v-model="form.account_name"
                        placeholder="请输入系统管理员"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="管理员手机号" prop="account_phone">
                      <el-input
                        clearable
                        v-model="form.account_phone"
                        placeholder="请输入管理员手机号"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="登录密码">
                      <el-input
                        placeholder="请输入登录密码"
                        show-password
                        v-model="form.password"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="启用状态" prop="credit_code">
                      <el-select
                        v-model="form.status"
                        placeholder="请选择启用状态"
                      >
                        <el-option label="启用" :value="0"></el-option>
                        <el-option label="未启用" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="15">
                  <el-col :span="12">
                    <el-form-item label="开放合作" prop="company_name">
                      <el-select
                        v-model="form.is_open_coop"
                        placeholder="是否合作"
                      >
                        <el-option label="是" :value="0"></el-option>
                        <el-option label="否" :value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-card>

          <!--基本信息-->
        </el-form>
        <!--按钮-->
        <div class="card_bottom">
          <el-button style="width: 200px !important" @click="returnPage"
            >取消</el-button
          >
          <el-button
            type="warning"
            style="width: 200px !important; margin-left: 20px"
            @click="save('form')"
            >提交</el-button
          >
        </div>
      </div>

      <!--企业钱包-->
      <div v-if="isShow == 2">
        <CorporateWallet :id="id" @showDetails="showisshow" />
      </div>

      <!--对账资料-->
      <!-- <div v-if="isShow == 2">
        <ReconciliationInformation :id="id" />
      </div> -->

      <!--会员信息-->
      <div v-if="isShow == 1">
        <Memberinfo :id="id" />
      </div>

      <!--话务配置-->
      <div v-if="isShow == 3">
        <Telefiguration :id="id" />
      </div>

      <!--小号管理-->
      <div v-if="isShow == 4">
        <Smallaccountmanagement :id="id" />
      </div>

      <!--合同信息-->
      <!-- <div v-if="isShow == 4">
        <ContractInformation :id="id" />
      </div> -->

      <!--计价公式-->
      <!-- <div v-if="isShow == 5">
        <Pricingformula :id="id" />
      </div> -->

      <!--短信设置模块-->
      <!-- <div v-if="isShow == 6">
        <smsSettings :id="id" />
      </div> -->
    </div>

    <!--系统设置模块-->
    <systemSettings
      :id="id"
      v-if="systemSettingsshow == true"
      @systemSettingsshow="SystemSettingsShow"
    />

    <el-dialog
      center
      :close-on-click-modal="false"
      v-if="dialogVisible1 == true"
      title="选择地址"
      top="5vh"
      :visible.sync="dialogVisible1"
      width="40%"
      :before-close="handleClose"
    >
      <div>
        <!--自定义高德选点组件-->
        <AMapLoadersiteselection
          v-if="showAMapLoadersiteselection == true"
          :data="origin"
          @location="getlocation"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
//引入企业钱包模块
import CorporateWallet from "./components/corporateWallet.vue";
//引入对账资料模块
import ReconciliationInformation from "./components/reconciliationInformation.vue";
//引入会员信息模块
import Memberinfo from "./components/memberinfo.vue";
//引入话务配置模块
import Telefiguration from "./components/Telephoneconfiguration.vue";
//引入小号配置模块
import Smallaccountmanagement from "./components/Smallaccountmanagement.vue";
//引入合同信息模块
import ContractInformation from "./components/contractInformation.vue";
// 引入计价公式组件
import Pricingformula from "./components/Pricingformula.vue";
//引入短信设置组件
import smsSettings from "./components/smsSettings.vue";
//引入系统设置组件
import systemSettings from "./systemSettings.vue";

// 获取配置
import setting from "@/config/setting";
import { getCookies } from "@/utils/cookies";
import Config from "@/config";

//引入的接口
import {
  Editfacilitators,
  Detailsfacilitators,
  getcityList,
} from "@/api/yunli";
import axios from "axios";
import { get_vip } from "@/api/custom";
import AMapLoadersiteselection from "@/components/AMapLoadersiteselection/index.vue";
export default {
  name: "index",
  // 支柱道具属性
  props: {
    myMessage: {
      type: String,
      default: "",
    },
    // 字典数据
    dict: Object,
    // id
    id: Number,
  },
  // 组件生效
  components: {
    CorporateWallet,
    // ReconciliationInformation,
    Memberinfo,
    // ContractInformation,
    // Pricingformula,
    // smsSettings,
    systemSettings,
    AMapLoadersiteselection,
    Telefiguration,
    Smallaccountmanagement
  },
  // Data数据
  data() {
    return {
      // 表单数据
      form: {
        password: "",
      },
      // 验证规则
      rules: {
        registered_capital: [
          { required: true, message: "请输入注册资本", trigger: "blur" },
        ],
        company_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入企业名称", trigger: "change" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        approve_status: [
          { required: true, message: "请选择认证状态", trigger: "change" },
        ],
        credit_code: [
          { required: true, message: "请输入企业信用代码", trigger: "blur" },
        ],
        registration_date: [
          { required: true, message: "请输入企业注册日期", trigger: "blur" },
        ],
        detailed_address: [
          { required: true, message: "请输入企业注册地址", trigger: "blur" },
        ],
        legal_person_identity_number: [
          {
            required: true,
            message: "请输入法定代表人身份证",
            trigger: "blur",
          },
        ],
        legal_person_name: [
          { required: true, message: "请输入法定代表人", trigger: "blur" },
        ],
        is_open_coop: [
          { required: true, message: "请选择是否开放合作", trigger: "change" },
        ],
        account_name: [
          { required: true, message: "请输入系统管理员", trigger: "blur" },
        ],
        account_phone: [
          { required: true, message: "请输入管理员手机号", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            type: "string",
            message: "请输入正确的手机号码",
            trigger: ["change", "blur"],
          },
        ],
        id_number: [
          { required: true, message: "身份证号不能为空" },
          {
            pattern:
              /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: "不是合法的身份证号！",
            trigger: "blur",
          },
        ],
        id_valid_time: [
          { required: true, message: "请选择身份证有效期", trigger: "change" },
        ],
        serve_district: [
          { required: true, message: "请选择区域", trigger: "serve_district" },
        ],
        drivers_number: [
          { required: true, message: "请输入驾驶证号码", trigger: "blur" },
        ],
        drivers_valid_time: [
          { required: true, message: "请选择驾驶证有效期", trigger: "change" },
        ],
        drivers_type: [
          { required: true, message: "请选择准驾车型", trigger: "change" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        dispatch_name: [
          { required: true, message: "请选择调度负责人", trigger: "blur" },
        ],
        dispatch_phone: [
          { required: true, message: "请选择调度负责人电话", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        facilitator_id: [
          { required: true, message: "请选择服务商", trigger: "change" },
        ],
        facilitator_abbreviation: [
          { required: true, message: "请填写简称", trigger: "change" },
        ],
        service_phone: [
          { required: true, message: "请填写企业客服电话", trigger: "change" },
        ],
        vehicle_id: [
          { required: true, message: "请选择绑定车辆", trigger: "change" },
        ],
        serve_time: [
          { required: true, message: "请选择服务时间", trigger: "change" },
        ],
        collection_account_name: [
          { required: true, message: "请填写账户名称", trigger: "blur" },
        ],
        collection_account_number: [
          { required: true, message: "请填写收款账号", trigger: "blur" },
        ],
        collection_opening_bank: [
          { required: true, message: "请填写开户行", trigger: "blur" },
        ],
        collection_bank_code: [
          { required: true, message: "收款账户联行号", trigger: "blur" },
        ],
        area_city_code: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },

      // 上传接口
      action: setting.apiBaseURL + "common/upload/capacity",
      // 上传请求头部
      headers: {
        "X-token": "Bearer" + " " + getCookies(Config.tokenStoreName),
      },

      //营业执照
      businessLicenseupload_btn: false,
      businessLicensedialogVisible: false,
      businessLicensedialogImageUrl: "",
      fileList: [],

      // 身份证正面
      dialogImageUrl: "",
      dialogVisible: false,
      upload_btn: false, // 是否隐藏上传图片按钮
      fileList1: [],

      //身份证反面
      oppositedialogImageUrl: "",
      oppositedialogVisible: false,
      oppositeupload_btn: false,
      fileList2: [],

      //企业logo
      logoupload_btn: false,
      logodialogVisible: false,
      logodialogImageUrl: "",
      fileList3: [],

      //登录页背景图
      bgupload_btn: false,
      bgdialogVisible: false,
      bgdialogImageUrl: "",
      fileList4: [],

      //城市列表
      city: [],

      // 注册地址
      registration_btn: false,
      registration_place: [],

      isShow: 0,
      activeList: [
        {
          // img: require("../../../../assets/images/home/custom/icon1active.png"),
          name: "基本信息",
        },
        {
          // img: require("../../../../assets/images/home/yunli/icon4.png"),
          name: "会员信息",
        },
        {
          // img: require("../../../../assets/images/home/yunli/icon2.png"),
          name: "企业钱包",
        },
        {
          // img: require("../../../../assets/images/home/yunli/icon2.png"),
          name: "话务配置",
        },
        {
          // img: require("../../../../assets/images/home/yunli/icon2.png"),
          name: "小号配置",
        },
        // {
        //   img: require('../../../../assets/images/home/yunli/icon3.png'),
        //   name: '对账资料',
        // },

        // {
        //   img: require('../../../../assets/images/home/yunli/icon5.png'),
        //   name: '合同信息',
        // },
        // {
        //   img: require('../../../../assets/images/home/yunli/icon6.png'),
        //   name: '计价公式',
        // },
        // {
        //   img: require('../../../../assets/images/home/yunli/icon7.png'),
        //   name: '短信设置',
        // },
        // {
        //   img: require('../../../../assets/images/home/yunli/icon8.png'),
        //   name: '系统设置',
        // }
      ],

      //系统设置模块
      systemSettingsshow: false,

      // 是否是会员
      is_vip: false,
      dialogVisible1: false,
      showAMapLoadersiteselection: false,
      //版本管理
      version: setting.version,
    };
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    console.log(this.myMessage);
    this.isShow = this.myMessage;
    if (this.myMessage == 1) {
      this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2active.png");
    }

    // 获取详情
    this.getDetails();

    // 获取城市
    this.getCity();
  },
  // 方法集合
  methods: {
    //获取是否为会员接口
    getVip() {
      get_vip().then((res) => {
        this.is_vip = res.data.status;
      });
    },
    opensiteselection(number) {
      // 用于判断是起点还是终点
      this.origin = number;
      this.dialogVisible1 = true;
      //this.showAMapLoadersiteselection = true;
      this.showAMapLoadersiteselection = false;
      this.$nextTick(() => {
        this.showAMapLoadersiteselection = true;
      });
    },
    getlocation(value) {
      // console.log(value)
      // 救援起点的位置
      this.form.detailed_address =
        (value.pname != undefined ? value.pname : "") +
        (value.cityname != undefined ? value.cityname : "") +
        (value.adname != undefined ? value.adname : "") +
        (value.name != undefined ? value.name : "");
      this.location = [];
      // 救援起点的经度纬度
      this.location[0] = value.location.lng;
      this.location[1] = value.location.lat;
      this.form.longitude = this.location[0];
      this.form.latitude = this.location[1];
      this.dialogVisible1 = false;
    },
    handleClose() {
      this.dialogVisible1 = false;
    },
    // 选项卡点击事件
    xuanxiangclick(item, index) {
      console.log(item);
      console.log(index);
      this.isShow = index;
      // if (item.name == "基本信息") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1active.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon2.png");
      // } else if (item.name == "会员信息") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon4active.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon2.png");
      // } else if (item.name == "企业钱包") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon2active.png");
      // } else if(item.name == "话务配置"){
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon2active.png");
      // }
      // if (item.name == "基本信息") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1active.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "企业钱包") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon2active.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "对账资料") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3active.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "会员信息") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2active.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "合同信息") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5active.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "计价公式") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6active.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "短信设置") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7active.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      // } else if (item.name == "系统设置") {
      //   this.activeList[0].img = require("../../../../assets/images/home/custom/icon1.png");
      //   this.activeList[1].img = require("../../../../assets/images/home/yunli/icon2.png");
      //   this.activeList[2].img = require("../../../../assets/images/home/yunli/icon3.png");
      //   this.activeList[3].img = require("../../../../assets/images/home/yunli/icon4.png");
      //   this.activeList[4].img = require("../../../../assets/images/home/yunli/icon5.png");
      //   this.activeList[5].img = require("../../../../assets/images/home/yunli/icon6.png");
      //   this.activeList[6].img = require("../../../../assets/images/home/yunli/icon7.png");
      //   this.activeList[7].img = require("../../../../assets/images/home/yunli/icon8.png");
      //   //显示系统设置模块
      //   this.systemSettingsshow = true;
      // }
    },

    // 返回上级页面
    returnPage() {
      this.$emit("showDetalis", false);
    },

    getCity() {
      axios({
        method: "GET",
        url: setting.commonurl + "/city_list",
      }).then((res) => {
        // console.log(res)
        this.city = res.data.data;
      });
    },
    citychange(value) {
      console.log(value);
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = areaTextArr[0];
      let city = areaTextArr[1];
      let district = areaTextArr[2];
      area.push(province, city, district);
      console.log(area);
      this.area = area;
    },
    // 获取详情信息
    getDetails() {
      Detailsfacilitators(this.id).then((res) => {
        console.log(res);
        this.form = res.data;

        // 营业执照
        if (this.form.business_license != "") {
          let data = {
            url: this.form.business_license,
          };
          this.businessLicenseupload_btn = true;
          this.fileList.push(data);
        }

        //正面
        if (this.form.legal_person_identify_front != "") {
          let data1 = {
            url: this.form.legal_person_identify_front,
          };
          this.upload_btn = true;
          this.fileList1.push(data1);
        }
        //反面
        if (this.form.legal_person_identify_reverse != "") {
          let data2 = {
            url: this.form.legal_person_identify_reverse,
          };
          this.oppositeupload_btn = true;
          this.fileList2.push(data2);
        }

        // 企业logo
        if (this.form.logo != "") {
          let data3 = {
            url: this.form.logo,
          };
          this.logoupload_btn = true;
          this.fileList3.push(data3);
        }

        // 登录页背景图
        if (this.form.login_background != "") {
          let data3 = {
            url: this.form.login_background,
          };
          this.bgupload_btn = true;
          this.fileList4.push(data3);
        }
      });
    },

    // 获取城市列表
    // getCity() {
    //   getcityList().then((res) => {
    //     // console.log(res);
    //     this.city = res.data;
    //   });
    // },

    //注册地址选择
    handleChange(value) {
      console.log(value);
      const areaTextArr = [];
      const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
      arr.forEach((i) => {
        areaTextArr.push(i.label);
      });
      // console.log(areaTextArr);
      // console.log(value);
      let area = [];
      let province = value[0] + "|" + areaTextArr[0];
      let city = value[1] + "|" + areaTextArr[1];
      let district = value[2] + "|" + areaTextArr[2];
      area.push(province, city, district);
      console.log(area);

      this.registration_btn = true;
      if (this.registration_btn == true) {
        this.registration_place = area;
      }
    },

    // 营业执照
    businessLicensePreview(file) {
      this.businessLicensedialogImageUrl = file.url;
      this.businessLicensedialogVisible = true;
    },
    businessLicensehandleRemove(file, fileList) {
      console.log(file, fileList);
      this.businessLicenseupload_btn = false;
    },
    businessLicensechange() {
      this.businessLicenseupload_btn = true;
    },
    //上传成功
    successUpload(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.businessLicensedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.business_license = file.data.urls;
    },

    //身份证正面
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.upload_btn = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    change() {
      this.upload_btn = true;
    },
    //上传成功
    successUpload1(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.dialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_front = file.data.urls;
    },

    //身份证反面
    oppositePreview(file) {
      this.oppositedialogImageUrl = file.url;
      this.oppositedialogVisible = true;
    },
    oppositehandleRemove(file, fileList) {
      this.oppositeupload_btn = false;
    },
    oppositechange() {
      this.oppositeupload_btn = true;
    },
    successUpload2(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.oppositedialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.legal_person_identify_reverse = file.data.urls;
    },

    //企业logo
    logoPreview(file) {
      this.logodialogImageUrl = file.url;
      this.logodialogVisible = true;
    },
    logohandleRemove(file, fileList) {
      this.logoupload_btn = false;
    },
    logochange() {
      this.logoupload_btn = true;
    },
    successUpload3(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.logodialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.logo = file.data.urls;
    },

    //登录页背景图
    bgPreview(file) {
      this.bgdialogImageUrl = file.url;
      this.bgdialogVisible = true;
    },
    bghandleRemove(file, fileList) {
      this.bgupload_btn = false;
    },
    bgchange() {
      this.bgupload_btn = true;
    },
    successUpload4(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.bgdialogImageUrl = file.data.urls;
      // 上传成功后的列表
      this.form.login_background = file.data.urls;
    },

    //提交
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.registration_btn == true) {
            this.form.registration_place = this.registration_place;
          }
          //编辑
          if (this.area) {
            this.form.area_city = this.area;
          }
          this.form.legal_person = this.form.legal_person_name;
          this.form.corporate_identity_card =
            this.form.legal_person_identity_number;
          this.form.principal_name = this.form.account_name;
          this.form.principal_phone = this.form.account_phone;

          console.log("this.form", this.form);
          Editfacilitators(this.id, this.form)
            .then((res) => {
              console.log(res);
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.$emit("showDetalis", false);
              } else if (res.code == 0) {
                this.$message.error(res.message);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((e) => {
              this.$message.error(e.msg);
            });
        } else {
          return false;
        }
      });
    },

    //接收系统设置子组件传递的值
    SystemSettingsShow(val) {
      this.systemSettingsshow = val;
      //默认显示的详情模块
      this.isShow = 0;
    },

    //接收企业钱包子组件传递的值
    showisshow(value) {
      this.isShow = 1;
    },
  },
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {},
  // 离开的时候触发
  deactivated() {},
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
}
.card {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;

  .card_t {
    display: flex;
    align-items: center;
  }

  .card_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }

  .card_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}

.card_bottom {
  margin-top: 80px;
  text-align: center;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }

  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }

  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }

  .condition_b {
    margin-top: 20px;

    .condition_b_img {
      width: 280px;
      height: 255px;
      background-image: url("../../../../assets/images/home/custom/upload_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      padding-top: 50px;
      cursor: pointer;
    }

    .condition_b_img_anniu {
      width: 94px;
      height: 94px;
    }

    .condition_b_img_text {
      font-size: 16px;
      font-weight: 500;
      color: #ff9b05;
    }

    .condition_b_l {
      width: 20%;
      float: left;
    }

    .condition_b_r {
      width: 76%;
      float: right;
      margin-left: 30px;
    }
  }

  // 解决高度坍塌
  .condition_b:after {
    content: "";
    clear: both;
    display: block;
  }
}

.xuanxiang {
  cursor: pointer;
  display: flex;

  .xuanxiang_k {
    width: 209px;
    height: 50px;
    background: #e8e8e8;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 22px;
      font-weight: 700;
      color: #7b7b7b;
      margin-left: 5px;
    }
  }

  .xuanxiang_k1 {
    width: 209px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border-top-right-radius: 30px;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 22px;
      font-weight: 700;
      color: #ff9b05;
      margin-left: 5px;
    }
  }
}

.hide_box /deep/ .el-upload--picture-card {
  display: none;
}

.upload_bg_logo >>> .el-upload-list--picture-card {
  width: 200px; /* 设置你想要的宽度 */
  height: 200px; /* 设置你想要的高度，如果需要的话 */
  /* 你也可以添加其他样式 */
}

//选择地点样式
.xuanzedidianyangshi {
  border-radius: 5px;
  -webkit-appearance: none;
  border: 1px solid var(--border-color-base);
  box-sizing: border-box;
  color: var(--color-text-regular);
  font-size: inherit;
  height: 36px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .didian {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}
</style>
