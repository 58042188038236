<template>
  <!--附近的市模块-->
  <div style="padding: 20px">
    <div class="title">
      {{ $route.meta.title }}
    </div>
    <el-row :gutter="15" style="margin-top: 20px;">
      <el-col :span="24">
        <el-card shadow="never">
          <el-row :gutter="15">
            <el-col :span="24">
              <div class="top">
                <div style="display: flex;align-items: center;">
                  <div>
                    <el-input
                      v-model="where.keyword"
                      placeholder="请输入市名称"
                      clearable
                    ></el-input>
                  </div>
                  <div>
                    <el-button type="warning" @click="search_s()">搜索</el-button>
                    <el-button  @click="search_exty()">清空</el-button>
                  </div>
                </div>
                <div>
                  <el-button type="warning" @click="addsheng(null)">新增</el-button>
                </div>
              </div>
              <div class="table_style">
                <my-table
                  ref="myTable"
                  :customsFromWhere="where"
                  :columns="columns"
                  :tableData="List"
                  :total="total"
                  :loading="loading"
                  @select="select"
                  @ChangeSize="ChangeSize"
                  @currentChange="currentChange"
                  :cellStyle="cellStyle"
                >

                  <template v-slot:near_city="{ scope }">
                    <span v-for="(item,index) in scope.row.near_city" :key="index">
                      {{item}},
                    </span>
                  </template>

                  <template v-slot:operationSlot="{ scope }">
                    <el-link
                      :underline="false"
                      type="warning"
                      @click="addsheng(scope.row)"
                      style="margin-right: 10px"
                    >编辑</el-link
                    >
                    <el-link
                      type="danger"
                      @click="delete_s( scope)"
                    >
                      删除
                    </el-link>
                  </template>
                </my-table>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :title="title" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false" top="5vh" >
      <!-- <span>这是一段信息</span> -->
      <el-form ref="form" :model="form" label-width="100px" label-position="left">
        <el-form-item label="城市名称" prop="city">
<!--<el-input clearable style="" v-model="form.city" placeholder="请输入城市名称"></el-input>-->
          <el-select v-model="value" clearable filterable :loading="city_loading" @visible-change="selectcity" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.code"
              :disabled="item.disabled"
              :label="item.name"
              :value="String(item.code)">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附近城市名称" prop="near_city">
        <!--<el-input clearable style="" v-model="form.near_city" placeholder="请输入附近城市名称"></el-input>-->
          <el-select v-model="value_s" multiple clearable filterable :loading="city_loading" @visible-change="selectcity" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.code"
              :label="item.name"
              :value="String(item.code)">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao_addcity">取 消</el-button>
        <el-button type="primary" @click="addcity">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

// 权限
import { ShowTable } from "@/utils";

// 引入的接口
import {
  city_list,
  city_code_list,
  city_create,
  city_del,
} from '@/api/system';

export default {
  props: {
    id: Number,
  },
  components: {

  },
  data() {
    return {
      form:{
        search:"",
      },
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      List: [],
      // 表头数据
      columns: [
        {
          label: "市",
          prop: "city",
          isShow: true,
        },
        {
          label: "附近的市",
          prop: "near_city",
          slot: 'near_city',
          isShow: true,
        },
        {
          label: "操作",
          width: "200",
          prop: "operationSlot",
          slot: "operationSlot",
          isShow: true,
        },
      ],
      //新增
      dialogVisible: false,
      //弹窗标题
      title:'',
      options: [],
      value: '',
      options_s: [],
      value_s:[],
      city_loading:true,
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getList();
    this.get_cityList();
  },

  methods: {
    // 获取列表
    getList(){
      this.loading = true;
      city_list(this.where).then(async (res) => {
        console.log(res);
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },
    //城市下拉--点击下拉事件
    selectcity(){

    },
    //获取城市列表
    get_cityList(){
      this.city_loading = true;
      city_code_list().then(async (res) => {
        console.log(res);
        this.options = res.data.list;
        this.city_loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.city_loading = false;
      })
    },
    search_s(){
      this.where.page = 1;
      this.where.limit = 10;
      this.getList();
    },
    search_exty(){
      this.where = {
        page: 1,
        limit: 10,
      }
      this.getList();
    },
    //添加
    addsheng(row) {
      console.log(row);
      if(row != null){
        this.title = '编辑附近城市';
        this.value = row.city_code;
        this.value_s = row.near_city_code;
      }else {
        this.get_cityList();
        this.title = '新增附近的市';
        this.value = '';
        this.value_s = [];
      }
      this.dialogVisible=true;
    },
    //删除
    delete_s(scope){
      let that = this;
      this.$utils.confirm("确定执行此操作吗？", function () {
        // 执行一些需要的逻辑
        city_del(scope.row.id).then(res => {
          if(res.code ==  200){
            that.$message.success(res.msg);
            that.getList();
          }else {
            that.$message.error(res.msg);
          }
        })
      }, "确定", 'warning');

    },
    //确定
    addcity(){
      if(this.value == ''){
        this.$message.error('请选择城市!')
        return false
      }
      if(this.value_s.length == 0){
        this.$message.error('请选择附近城市!')
        return false
      }

      let data = {
        id:0,
        city:this.value,
        near_city:this.value_s
      }
      city_create(data).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //取消
    quxiao_addcity(){
      this.dialogVisible = false;
      this.value = '';
      this.value_s = [];
    },

    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    // 语音通知当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input_style{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table_style{
  margin: 20px;
}
.save_style{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
