<template>
  <!--通话记录-->
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <div style="margin-top: 20px;">
        <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
          <el-tab-pane label="呼叫系统外呼和呼入的通话记录" name="first">
            <!--搜索条件-->
            <div class="card">
              <div class="card_t">
                <div class="card_t_icon"></div>
                <div class="card_t_text">筛选条件</div>
              </div>
              <div class="card_b">
                <!-- 搜索表单 -->
                <el-form
                  :model="record_where"
                  label-position="left"
                  label-width="90px"
                  class="ele-form-search"
                  @keyup.enter.native="reload"
                  @submit.native.prevent
                >
                  <el-row :gutter="15">
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="100px" label="服务商名称">
                        <el-input
                          v-model="record_where.keyword"
                          placeholder="请输入服务商名称/员工名称"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="订单号">
                        <el-input
                          v-model="record_where.odd"
                          placeholder="请输入订单号"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="主叫号码">
                        <el-input
                          v-model="record_where.calling"
                          placeholder="请输入主叫号码"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="被叫号码">
                        <el-input
                          v-model="record_where.called"
                          placeholder="请输入被叫号码"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="呼叫角色">
                        <el-select v-model="record_where.tel_type" clearable placeholder="请选择">
                          <el-option label="其他" value="0"/>
                          <el-option label="车主" value="1"/>
                          <el-option label="服务商" value="2"/>
                          <el-option label="客户(上游)" value="3"/>
                          <el-option label="司机" value="4"/>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="呼叫类型">
                        <el-select v-model="record_where.call_type" clearable placeholder="请选择">
                          <el-option label="呼入" value="0"/>
                          <el-option label="呼出" value="1"/>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="8" :md="12">
                      <el-form-item label="通话时间:">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH-mm-ss"
                          v-model="record_where.start_time"
                          type="datetimerange"
                          unlink-panels
                          :default-time="['00:00:00', '23:59:59']"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col
                      :lg="24"
                      :md="12"
                      style="text-align: center"
                      class="flex-center"
                    >
                      <div class="ele-form-actions">
                        <el-button
                          type="primary"
                          class="ele-btn-icon"
                          @click="record_QueryList"
                        >查询
                        </el-button>
                        <el-button @click="record_empty">清空</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--数据表格-->
            <div class="card">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">数据列表</div>
                </div>
              </div>
              <div class="card_b">
                <!-- 数据表格 -->
                <my-table
                  ref="myTable"
                  :columns="record_columns"
                  :tableData="record_list"
                  :cellStyle="record_cellStyle"
                  :headerCellStyle="record_headerCellStyle"
                  :rowClassName="record_rowClassName"
                  :customsFromWhere="record_where"
                  :loading="record_loading"
                  :total="record_total"
                  @select="record_select"
                  @ChangeSize="record_ChangeSize"
                  @currentChange="record_currentChange"
                >
                  <!--通话人-->
                  <template v-slot:company_name="{ scope }">
                    <div>
                      {{scope.row.company_name}} {{scope.row.realname}}
                    </div>
                  </template>
                  <!--录音文件-->
                  <template v-slot:tel_url="{ scope }">
                    <div>
                      <audio
                        v-if="scope.row.tel_url != ''"
                        :src="scope.row.tel_url"
                        controls="controls"
                        ref="audio"
                      >
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </template>
                </my-table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="虚拟号拨打记录" name="second">
            <!--搜索条件-->
            <div class="card">
              <div class="card_t">
                <div class="card_t_icon"></div>
                <div class="card_t_text">筛选条件</div>
              </div>
              <div class="card_b">
                <!-- 搜索表单 -->
                <el-form
                  :model="virtual_where"
                  label-position="left"
                  label-width="90px"
                  class="ele-form-search"
                  @keyup.enter.native="reload"
                  @submit.native.prevent
                >
                  <el-row :gutter="15">
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="100px" label="服务商名字">
                        <el-input
                          v-model="virtual_where.keyword"
                          placeholder="请输入服务商名字"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="4" :md="14">
                      <el-form-item label-width="100px" label="订单号">
                        <el-input
                          v-model="virtual_where.odd"
                          placeholder="请输入订单号"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="100px" label="主叫号码">
                        <el-input
                          v-model="virtual_where.no_a"
                          placeholder="请输入主叫号码"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="100px" label="被叫号码">
                        <el-input
                          v-model="virtual_where.no_b"
                          placeholder="请输入被叫号码"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="100px" label="虚拟号">
                        <el-input
                          v-model="virtual_where.no_x"
                          placeholder="请输入虚拟号"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="15">

                    <el-col :lg="8" :md="12">
                      <el-form-item label="通话时间:">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH-mm-ss"
                          v-model="virtual_where.start_time"
                          type="datetimerange"
                          unlink-panels
                          :default-time="['00:00:00', '23:59:59']"
                          range-separator="至"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col
                      :lg="24"
                      :md="12"
                      style="text-align: center"
                      class="flex-center"
                    >
                      <div class="ele-form-actions">
                        <el-button
                          type="primary"
                          class="ele-btn-icon"
                          @click="virtual_QueryList"
                        >查询
                        </el-button>
                        <el-button @click="virtual_empty">清空</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--数据表格-->
            <div class="card">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">数据列表</div>
                </div>
              </div>
              <div class="card_b">
                <!-- 数据表格 -->
                <my-table
                  ref="myTable"
                  :columns="virtual_columns"
                  :tableData="virtual_list"
                  :cellStyle="virtual_cellStyle"
                  :headerCellStyle="virtual_headerCellStyle"
                  :rowClassName="virtual_rowClassName"
                  :customsFromWhere="virtual_where"
                  :loading="virtual_loading"
                  :total="virtual_total"
                  @select="virtual_select"
                  @ChangeSize="virtual_ChangeSize"
                  @currentChange="virtual_currentChange"
                >
                  <!--录音文件-->
                  <template v-slot:tel_url="{ scope }">
                    <div>
                      <audio
                        v-if="scope.row.tel_url != ''"
                        :src="scope.row.tel_url"
                        controls="controls"
                        ref="audio"
                      >
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </template>
                </my-table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="语音通知记录" name="third">
            <!--搜索条件-->
            <div class="card">
              <div class="card_t">
                <div class="card_t_icon"></div>
                <div class="card_t_text">筛选条件</div>
              </div>
              <div class="card_b">
                <!-- 搜索表单 -->
                <el-form
                  :model="where"
                  label-position="left"
                  label-width="90px"
                  class="ele-form-search"
                  @keyup.enter.native="reload"
                  @submit.native.prevent
                >
                  <el-row :gutter="15">
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="通知内容">
                        <el-input
                          v-model="where.keyword"
                          placeholder="通知内容或通知手机号"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label-width="80px" label="订单号">
                        <el-input
                          v-model="where.odd"
                          placeholder="请输入订单号"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label="所属服务商:">
                        <el-select
                          filterable
                          clearable
                          v-model="where.Facilitator_id"
                          placeholder="请选择"
                          class="ele-fluid">
                          <el-option
                            v-for="(item,index) in all_selectList"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :lg="5" :md="14">
                      <el-form-item label="通知角色:">
                        <el-select
                          filterable
                          clearable
                          v-model="where.identity"
                          placeholder="请选择"
                          class="ele-fluid">
                          <el-option
                            v-for="(item,index) in notificationRoles"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col
                      :lg="24"
                      :md="12"
                      style="text-align: center"
                      class="flex-center"
                    >
                      <div class="ele-form-actions">
                        <el-button
                          type="primary"
                          class="ele-btn-icon"
                          @click="QueryList"
                        >查询
                        </el-button>
                        <el-button @click="empty">清空</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--数据表格-->
            <div class="card">
              <div class="Datalist">
                <div class="Datalist_l">
                  <div class="Datalist_icon"></div>
                  <div class="Datalist_text">数据列表</div>
                </div>
              </div>
              <div class="card_b">
                <!-- 数据表格 -->
                <my-table
                  ref="myTable"
                  :columns="columns"
                  :tableData="List"
                  :cellStyle="cellStyle"
                  :headerCellStyle="headerCellStyle"
                  :rowClassName="rowClassName"
                  :customsFromWhere="where"
                  :loading="loading"
                  :total="total"
                  @select="select"
                  @ChangeSize="ChangeSize"
                  @currentChange="currentChange"
                >
                </my-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

    </div>
  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTables";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {
  voice_list,
  virtual_list,
  call_list
} from '@/api/operate';
import {all_select} from "@/api/yunli";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
  },
  // Data数据
  data() {
    return {
      activeName: 'first',
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 语音通知表格数据
      List: [],
      // 语音通知表头数据
      columns: [
        {
          label: "订单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "通知电话",
          prop: "phone",
          isShow: true,
        },
        {
          label: "通知内容",
          prop: "text",
          isShow: true,
        },
        {
          label: "时间",
          prop: "time",
          isShow: true,
        },
        {
          label: "通知角色",
          prop: "identity_label",
          isShow: true,
        },
      ],
      //虚拟号拨打加载
      virtual_loading:true,
      // 虚拟号表格搜索条件
      virtual_where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 总条目数
      virtual_total: 0,
      // 虚拟号拨打记录表格数据
      virtual_list:[],
      // 虚拟号表头数据
      virtual_columns: [
        {
          label: "订单号",
          prop: "order_id",
          isShow: true,
        },
        {
          label: "服务商名称",
          prop: "company_name",
          isShow: true,
        },
        {
          label: "主叫号码",
          prop: "no_a",
          isShow: true,
        },
        {
          label: "X/Y号码",
          prop: "no_x",
          isShow: true,
        },
        {
          label: "被叫号码",
          prop: "no_b",
          isShow: true,
        },
        {
          label: "通话时间",
          prop: "start_time",
          isShow: true,
        },
        {
          label: "响铃时间",
          prop: "ring_time",
          isShow: true,
        },
        {
          label: "释放时间",
          prop: "release_time",
          isShow: true,
        },
        {
          label: "释放方向",
          prop: "release_dir",
          isShow: true,
        },
        {
          label: "应答时间",
          prop: "answer_time",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true,
        },
        {
          label: "通话时长(分钟)",
          prop: "duration",
          isShow: true,
        },
        {
          label: "录音文件",
          width:"350",
          prop: "tel_url",
          slot:"tel_url",
          isShow: true,
        },
        {
          label: "描述",
          prop: "tel_type_label",
          isShow: true,
        },
      ],
      // 所有服务商
      all_selectList:[],
      // 通知角色
      notificationRoles:[
        {
          label: "调度人员",
          value: "dispatcher",
        },
        {
          label: "服务商",
          value: "facilitator",
        },
        {
          label: "司机",
          value: "driver",
        },
      ],
      //记录拨打加载
      record_loading:true,
      // 记录表格搜索条件
      record_where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 总条目数
      record_total: 0,
      // 记录拨打记录表格数据
      record_list:[],
      // 记录表头数据
      record_columns: [
        {
          label: "通话人",
          prop: "company_name",
          slot:'company_name',
          isShow: true,
        },
        {
          label: "订单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "主叫号码",
          prop: "calling",
          isShow: true,
        },
        {
          label: "被叫号码",
          prop: "called",
          isShow: true,
        },
        {
          label: "通话状态",
          prop: "status_lable",
          isShow: true,
        },
        {
          label: "呼叫角色",
          prop: "tel_type_lable",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          isShow: true,
        },
        {
          label: "呼叫类型",
          prop: "call_type_lable",
          isShow: true,
        },
        {
          label: "通话分钟数",
          prop: "duration",
          isShow: true,
        },
        {
          label: "通话时长",
          prop: "talk_time",
          isShow: true,
        },
        {
          label: "录音文件",
          width:"350",
          prop: "tel_url",
          slot:"tel_url",
          isShow: true,
        },
      ],
      // 列表设置弹窗
      ListSetupShow: false,
    };
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getall_select();
    if(this.activeName == 'second'){
      this.virtual_where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.get_virtual_list();
    }else if(this.activeName == 'third'){
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    }else if(this.activeName == 'first'){
      this.record_where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.get_call_list();
    }
  },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  // 方法集合
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if(this.activeName == 'second'){
        this.virtual_where = {
          page: 1, // 当前页数
          limit: 10, // 每页显示条目个数
        };
        this.get_virtual_list();
      }else if(this.activeName == 'third'){
        this.where = {
          page: 1, // 当前页数
          limit: 10, // 每页显示条目个数
        };
        this.getList();
      }else if(this.activeName == 'first'){
        this.record_where = {
          page: 1, // 当前页数
          limit: 10, // 每页显示条目个数
        };
        this.get_call_list();
      }
    },
    // 获取全部服务商
    getall_select(){
      this.loading1 = true;
      all_select().then(res => {
        this.all_selectList = res.data;
        this.loading1 = false;
      })
    },
    //语音通知表格数据
    getList() {
      voice_list(this.where).then((res) => {
        console.log(res);
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      });
    },
    //虚拟号拨打表格数据
    get_virtual_list() {
      virtual_list(this.virtual_where).then((res) => {
        console.log(res);
        this.virtual_total = res.data.count;
        this.virtual_list = res.data.list;
        this.virtual_loading = false;
      });
    },
    //外呼系统呼叫表格数据
    get_call_list(){
      call_list(this.record_where).then((res) => {
        console.log(res);
        this.record_total = res.data.count;
        this.record_list = res.data.list;
        this.record_loading = false;
      });
    },
    // 语音通知查询条件
    QueryList() {
      this.getList();
    },
    // 语音通知清空
    empty() {
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.getList();
    },
    // 虚拟号查询条件
    virtual_QueryList(){
      this.get_virtual_list();
    },
    //虚拟号拨打清空
    virtual_empty(){
      this.virtual_where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.get_virtual_list();
    },
    // 记录通知查询条件
    record_QueryList() {
      this.get_call_list();
    },
    // 语音通知清空
    record_empty() {
      this.record_where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      };
      this.get_call_list();
    },
    // 语音通知当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    //虚拟号当前页数改变事件
    virtual_currentChange(val){
      // console.log("当前页数", val)
      this.virtual_where.page = val;
      this.get_virtual_list();
    },
    //记录当前页数改变事件
    record_currentChange(val){
      // console.log("当前页数", val)
      this.record_where.page = val;
      this.get_call_list();
    },
    // 语音通知当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    // 虚拟号当用户手动勾选数据行的 Checkbox 时触发的事件
    virtual_select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    // 记录当用户手动勾选数据行的 Checkbox 时触发的事件
    record_select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    //语音通知
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    //虚拟号
    virtual_ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.virtual_where.limit = val;
      this.get_virtual_list();
    },
    //记录
    record_ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.record_where.limit = val;
      this.get_call_list();
    },
    // 语音通知--表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 虚拟号--表头行的 className 的回调方法
    virtual_headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 记录--表头行的 className 的回调方法
    record_headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 语音通知--表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 虚拟号--表头单元格的 style 的回调方法
    virtual_headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 记录--表头单元格的 style 的回调方法
    record_headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    //语音通知-- 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    //虚拟号-- 行的 className 的回调方法
    virtual_rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    //记录-- 行的 className 的回调方法
    record_rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 语音通知--单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    //虚拟号-- 单元格的 style 的回调方法
    virtual_cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    //记录-- 单元格的 style 的回调方法
    record_cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
