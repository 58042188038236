// 请求接口地址 如果没有配置自动获取当前网址路径
// const VUE_APP_API_URL = process.env.VUE_APP_API_URL || `${location.origin}/adminapi`
// const VUE_APP_API_URL = 'https://kzshw.iswiiey.cn:26886/adminapi/' || `${location.origin}/adminapi`

// 公网接口
//const VUE_APP_API_URL = 'http://106.115.25.252:9001/superman/' || `${location.origin}/adminapi`
// 线上接口

var VUE_APP_API_URL, COMMON_URL, CONTRACT_URL;

// 本地环境
// VUE_APP_API_URL = 'https://api.jiuyuanshifu.cn:26686/superman/' || `${location.origin}/adminapi`
// COMMON_URL = 'https://api.jiuyuanshifu.cn:26686/globals'
// CONTRACT_URL = 'https://api.jiuyuanshifu.cn:26686/qzfw_agent_request'
// 本地环境(分支)
// VUE_APP_API_URL = 'https://api1020.jiuyuanshifu.cn:26686/superman/' || `${location.origin}/adminapi`
// COMMON_URL = 'https://api1020.jiuyuanshifu.cn:26686/globals'
// CONTRACT_URL = 'https://api1020.jiuyuanshifu.cn:26686/qzfw_agent_request'

// 测试环境'
VUE_APP_API_URL = 'https://api.jiuyuanbao.net/superman/' || `${location.origin}/adminapi`
COMMON_URL = 'https://api.jiuyuanbao.net/globals'
CONTRACT_URL = 'https://api.jiuyuanbao.net/qzfw_agent_request'

// 正式环境
// VUE_APP_API_URL = 'https://api.jiuyuanbao.cn/superman/' || `${location.origin}/adminapi`
// COMMON_URL  = 'https://api.jiuyuanbao.cn/globals'
// CONTRACT_URL  = 'https://api.jiuyuanbao.cn/qzfw_agent_request'


// 管理端ws 线上 wss适用于https0
const VUE_APP_WS_ADMIN_URL = `wss://saas.api.bxxt.cn/admin_work`

const Setting = {
  // 接口请求地址
  apiBaseURL: VUE_APP_API_URL,
  // adminsocket连接
  wsAdminSocketUrl: VUE_APP_WS_ADMIN_URL,
  // 电子合同url地址
  contracturl: CONTRACT_URL,
  // 公共地址
  commonurl: COMMON_URL,
  // 版本 线上测试本地都是1
  version: 1,
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
}

export default Setting
