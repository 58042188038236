<!--平台会员设置-->
<template>
  <div>
    <div style="margin-top: 20px; text-align: center">
      <el-form
        label-width="90px"
        class="ele-form-search"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="10">
          <el-col :lg="9" :md="14" class="grop-item-to">
            <el-form-item label-width="135px" label="会员权重" prop="weight">
              <el-input
                v-model.number="form.weight"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 1 && (this.value = '') || this.value>100 && (this.value = 100)"
                @blur="form.weight = $event.target.value"
                maxlength="3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item label-width="135px" label="会员名称" prop="title">
              <el-input
                v-model="form.title"
                placeholder="请输入"
                clearable
                maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="短信单价"
              prop="note_price"
            >
              <el-input
                v-model="form.note_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="赠送短信条数"
              prop="give_note"
            >
              <el-input
                v-model.number="form.give_note"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="电话单价"
              prop="phone_price"
            >
              <el-input
                v-model="form.phone_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="赠送电话时长"
              prop="give_phone"
            >
              <el-input
                v-model.number="form.give_phone"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="语音提醒单价"
              prop="voice_price"
            >
              <el-input
                v-model="form.voice_price"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="150px"
              label="赠送语音提醒次数"
              prop="give_voice"
            >
              <el-input
                v-model.number="form.give_voice"
                placeholder="请输入"
                clearable
                type="number"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="149px"
              label="提现手续费比例%"
              prop="commission"
            >
              <el-input
                v-model="form.commission"
                placeholder="请输入"
                clearable
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                maxlength="5"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item
              label-width="135px"
              label="最低提现金额"
              prop="min_withdrawal"
            >
              <el-input
                v-model="form.min_withdrawal"
                placeholder="请输入"
                clearable
                oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="7" :md="14">
            <el-form-item label-width="50px" label="状态" ref="radio">
              <el-radio-group v-model="form.status">
                <el-radio :label="0">启用</el-radio>
                <el-radio :label="1">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="3">
          <el-col :lg="2" :md="14">
            <el-form-item label-width="135px" label="会员权益"> </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox v-model="form.rights.rtgs.switch" >{{
                  form.rights.rtgs.label
                }}</el-checkbox>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox v-model="form.rights.facilitator.switch" @change="tuiguang_change">
                  推广服务商返佣
                </el-checkbox>
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="14">
            <el-form-item
              label-width="185px"
              :label="form.rights.facilitator.member_brokerage.label"
            >
              <div class="flex_start">
                <el-input
                  v-model="
                    form.rights.facilitator.member_brokerage.value
                  "
                  :disabled ="!form.rights.facilitator.switch"
                  placeholder="请输入"
                  type="number"
                  @mousewheel.native.prevent
                  @DOMMouseScroll.native.prevent
                  @blur="InputE2(form.rights.facilitator.member_brokerage)"
                  clearable
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox v-model="form.rights.shared.switch" @change="geti_change">
                  推广个体司机返佣
                </el-checkbox>
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="14">
            <el-form-item
              label-width="210px"
              :label="form.rights.shared.member_brokerage.label"
            >
              <div class="flex_start">
                <el-input
                  v-model="form.rights.shared.member_brokerage.value"
                  type="number"
                  @mousewheel.native.prevent
                  @DOMMouseScroll.native.prevent
                  :disabled ="!form.rights.shared.switch"
                  @blur="InputE3(form.rights.shared.member_brokerage)"
                  placeholder="请输入"
                  clearable
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="14">
            <el-form-item
              label-width="200px"
              :label="form.rights.shared.order_brokerage.label"
            >
              <div class="flex_start">
                <el-input
                  v-model="form.rights.shared.order_brokerage.value"
                  placeholder="请输入"
                  type="number"
                    :disabled ="!form.rights.shared.switch"
                  @mousewheel.native.prevent
                  @DOMMouseScroll.native.prevent
                  @blur="InputE4(form.rights.shared.order_brokerage)"
                  clearable
                >
                  <template slot="append">%</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox v-model="form.rights.raffle.switch" @change="get_choujiang">
                  司机在线抽奖
                </el-checkbox>
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="14">
            <el-form-item label-width="185px" label="推广个体司机数量">
              <div class="flex_start">
                <el-input
                  v-model="form.rights.raffle.shared_count.value"
                  placeholder="请输入"
                  clearable
                  type="number"
                  :disabled ="!form.rights.raffle.switch"
                  @mousewheel.native.prevent
                  @DOMMouseScroll.native.prevent
                  @blur="form.rights.raffle.shared_count.value = $event.target.value"
                  oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>10000 && (this.value = 10000)"
                >
                  <template slot="append">个</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="14">
            <el-form-item label-width="160px" label="每日在线时长≥">
              <div class="flex_start">
                <el-input
                  v-model="form.rights.raffle.daily_online.value"
                  placeholder="请输入"
                  clearable
                  type="number"
                   :disabled ="!form.rights.raffle.switch"
                  @mousewheel.native.prevent
                  @DOMMouseScroll.native.prevent
                  @blur="form.rights.raffle.daily_online.value = $event.target.value"
                  oninput="this.value = this.value && parseInt(this.value);this.value < 0 || this.value>24 && (this.value = 24)"
                >
                  <template slot="append">小时</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="7.5px">
              <el-checkbox v-model="form.checked">推广服务商返佣</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item label-width="183px" label="服务商充值会员返佣比例">
              <el-input v-model="form.name" placeholder="请输入" clearable
                ><template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="6" :md="14">
            <el-form-item label-width="20px">
              <el-checkbox v-model="form.checked">推广个体司机返佣</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item label-width="183px" label="个体司机充值会员返佣比例">
              <el-input v-model="form.name" placeholder="请输入" clearable
                ><template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="9" :md="14">
            <el-form-item label-width="183px" label="个体司机订单收益返佣比例">
              <el-input
                v-model="form.name"
                placeholder="请输入"
                clearable
                maxlength="10"
                ><template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="4" :md="14">
            <el-form-item label-width="114px">
              <el-checkbox v-model="form.checked">司机在线抽奖</el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :lg="10" :md="14">
            <el-form-item label-width="303px" label="推广个体司机数量">
              <el-input v-model="form.name" placeholder="请输入" clearable
                ><template slot="append">个</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="10" :md="14">
            <el-form-item label-width="246px" label="每日在线时长≥">
              <el-input v-model="form.name" placeholder="请输入" clearable
                ><template slot="append">小时</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
        <el-row :gutter="10">
          <el-col :lg="4" :md="14">
            <el-form-item label-width="135px">
              <div>抽奖转盘配置</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="24" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_choujiang">
                <div class="w-30">
                  <div>区块</div>
                  <div class="border-top-border">中奖概率/%</div>
                  <div class="border-top-border">中奖金额/元</div>
                </div>
                <div v-for="(item, index) in form.raffle" :key="index">
                  <div class="border-right-left">{{ item.label }}</div>
                  <div>
                    <el-input
                      v-model="item.odds"
                      placeholder="请输入"
                      clearable
                      @blur="InputE(item)"
                      :disabled="!form.rights.raffle.switch"
                      maxlength="10"
                    ></el-input>
                  </div>
                  <div>
                    <el-input
                      v-model="item.money"
                      placeholder="请输入"
                       :disabled="!form.rights.raffle.switch"
                      @blur="InputE1(item)"
                      clearable
                    ></el-input>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="24" :md="14"></el-col>
          <el-col :lg="24" :md="14">
            <el-form-item label-width="135px" label="会员费">
              <!-- <el-row :gutter="20"> -->
              <div style="display: flex; justify-content: flex-end">
                <el-button type="warning" @click="costAdd">添加</el-button>
              </div>
              <!-- </el-row> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="24" :md="14">
            <!-- {{form.cost}} -->
            <el-form-item label-width="135px">
              <el-table
                border
                :data="form.cost"
                size="mini"
                style="width: 100%"
              >
                <el-table-column prop="duration" label="时长" width="180">
                  <template slot-scope="scope">
                    <!-- <el-input
                      class="item"
                      v-model="scope.row.duration"
                      placeholder="请输入内容"
                    ></el-input> -->
                    <el-select
                      clearable
                      class="ele-block"
                      v-model="scope.row.duration"
                      placeholder="请选择时长"
                      @change="handleSelectChange(scope.$index)"
                    >
                      <el-option
                        v-for="option in time_options"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      >
                      </el-option>
                      <!-- <el-option label="1年" :value="0" />
                      <el-option label="2年" :value="1" /> -->
                      <!-- <el-option label="微信小程序" :value="3"/>
          <el-option label="APP应用" :value="4"/> -->
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="money" label="金额/元" width="180">
                  <template slot-scope="scope">
                    <el-input
                      class="item"
                      oninput="value=value.replace(/^([0-9-]\d*\.?\d{0,2})?.*$/,'$1')"
                      placeholder="请输入"
                      v-model="scope.row.money"
                      clearable
                    >
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" label="权重" width="180">
                  <template slot-scope="scope">
                    <el-input
                      class="item"
                      v-model.number="scope.row.weight"
                      type="number"
                      @mousewheel.native.prevent
                      @DOMMouseScroll.native.prevent
                      oninput="this.value = this.value && parseInt(this.value);this.value < 1 && (this.value = '') || this.value>100 && (this.value = 100)"
                      placeholder="请输入内容"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-link type="danger" @click="del(scope.$index)"
                      >删除</el-link
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="3">
          <el-col :lg="2" :md="14">
            <el-form-item label-width="135px" label="可用功能">
              <!-- <el-checkbox v-model="form.checked">实时结算</el-checkbox> -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :lg="24" :md="14">
            <el-form-item label-width="135px">
              <div class="flex_start">
                <el-checkbox
                  v-model="checkAll"
                  :indeterminate="isIndeterminate"
                  @change="handleCheckAllChange"
                >
                  全选
                </el-checkbox>
              </div>

              <el-scrollbar
                style="height: 50vh"
                wrapStyle="overflow-x: hidden;"
              >
                <el-tree
                  :check-strictly="isCheck"
                  :expand-on-click-node="false"
                  ref="tree"
                  :data="authData"
                  :props="{ label: 'title' }"
                  node-key="id"
                  :default-expand-all="false"

                  show-checkbox
                >
                  <span slot-scope="{ data }">
                    <!--<i :class="data.icon"></i>-->
                    <span> {{ data.label }}</span>
                  </span>
                </el-tree>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-button style="width: 240px" @click="close_emit">取消</el-button>
      <el-button
        style="margin-left: 20px; width: 240px"
        type="primary"
        @click="save('form')"
        >提交
      </el-button>
    </div>
  </div>
</template>

<script>
//引入的接口
import {
  cascader_list,
  get_relevancy,
  relevancy_menus,
} from "@/api/humanResources";
import { getMenusListApi, getMenusCascaderApi } from "@/api/system";
import {
  v2_platform,
  huiyuan_add,
  huiyuan_detail,
  huiyuan_xiugai,
  get_menu,
} from "@/api/operate";

import axios from "axios";
import setting from "@/config/setting";
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      // 权限数据
      authData: [],
      // 权限数据请求状态
      authLoading: false,
      // 提交状态
      loading: false,

      isCheck: false,
      form: {
        weight: 0,
        title:'',
        note_price: 0,
        give_note: 0,
        phone_price: 0,
        give_phone:0,
        voice_price:0,
        give_voice:0,
        commission:0,
        min_withdrawal:0,
        status:0,
        checked: false,
        rights: {
          facilitator: {
            label: "推广服务商返佣",
            switch: false,
            member_brokerage: {
              label: "服务商充值会员返佣比例",
              value: "",
            },
          },
          rtgs: {
            label: "实时结算",
            switch: false,
          },
          shared: {
            label: "推广个体司机返佣",
            switch: false,
            member_brokerage: {
              label: "个体司机充值会员返佣比例",
              value: "",
            },
            order_brokerage: {
              label: "个体司机订单收益返佣比例",
              value: "",
            },
          },
          raffle: {
            shared_count: {value:0},
            daily_online:{value:0},
          },
          cost:[]
        },
      },
      rules: {
        weight: [
          { required: true, message: "请输入会员权重", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入会员名称", trigger: "blur" }],
        note_price: [
          { required: true, message: "请输入短信单价", trigger: "blur" },
        ],
        give_note: [
          { required: true, message: "请输入短信赠送条数", trigger: "blur" },
        ],
        phone_price: [
          { required: true, message: "请输入网络电话单价", trigger: "blur" },
        ],
        give_phone: [
          {
            required: true,
            message: "请输入网络电话赠送条数",
            trigger: "blur",
          },
        ],
        voice_price: [
          { required: true, message: "请输入语音通知单价", trigger: "blur" },
        ],
        give_voice: [
          { required: true, message: "请输入语音赠送条数", trigger: "blur" },
        ],
        commission: [
          { required: true, message: "请输入提现手续费比例", trigger: "blur" },
        ],
        min_withdrawal: [
          { required: true, message: "请输入最低提现金额", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入最低提现金额", trigger: "blur" },
        ],
      },
      data: [],
      time_options: [],
      time_options_copy: [],
    };
  },

  mounted() {
    axios
      .get(setting.commonurl + "/get_dict", {
        params: {
          dict_type: "memberDate",
        },
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.time_options = res.data.data;
          this.time_options_copy = res.data.data;
        } else {
          console.log(res);
        }
        // console.log(res);
      });
    console.log(this.id);
    if (this.id != 0) {
      this.get_detail();
    } else {

      this.get_config_list();
    }
  },
  computed: {
    // 权限树选中数据
    checked() {
      let checked = [];
      this.$util.eachTreeData(this.authData, (d) => {
        if (d.checked && (!d.children || !d.children.length)) {
          checked.push(d.id);
        }
      });
      return checked;
    },
  },
  methods: {
    get_choujiang(value){
      console.log(value);
      if(!value){
         for (const item of Object.entries(this.form.raffle)) {
          // console.log(item[1].money, item[1].odds);
           item[1].money = ""
           item[1].odds = ""
        }
        this.form.rights.raffle.shared_count.value = ""
        this.form.rights.raffle.daily_online.value = ""

      }
    },
    geti_change(value){
       if(!value){
          this.form.rights.shared.member_brokerage.value = ""
          this.form.rights.shared.order_brokerage.value = ""
       }
    },
    tuiguang_change(value){
       if(!value){
         this.form.rights.facilitator.member_brokerage.value = ""
       }
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    // save() {
    //   console.log(this.tableData);
    // },
    //下拉选择时间
    handleSelectChange(value) {
      // console.log(value)
      let dur = this.form.cost[value].duration;
      const index = this.time_options_copy.findIndex(
        (option) => option.id === dur
      );
      this.form.cost[value].label = this.time_options_copy[index].name;
      let data = this.time_options_copy;
      const diff2 = data.filter(
        (item2) => !this.form.cost.some((item1) => item1.duration === item2.id)
      );
      // console.log(diff2);
      this.time_options = diff2;
    },
    get_config_list() {
      v2_platform()
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data);
            this.form = res.data;
            this.query();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    costAdd() {
      this.form.cost.push({
        duration: "",
        label: "",
        money: "",
        weight: 0,
      });
    },
    del(index) {
      console.log(index);
      this.form.cost.splice(index, 1);
      let data = this.time_options_copy;
      console.log(data);
      const diff2 = data.filter(
        (item2) => !this.form.cost.some((item1) => item1.duration === item2.id)
      );
      this.time_options = diff2;
    },
    /** 鼠标移出cell */

    get_detail() {
      if (this.id != 0) {
        huiyuan_detail(this.id).then((ress) => {
          if (ress.code === 200) {
            this.form = ress.data;
            this.query();
            setTimeout(() => {
              let data = this.time_options_copy;
              console.log(this.time_options_copy);
              console.log(data, this.form.cost);
              const diff2 = data.filter(
                (item2) =>
                  !this.form.cost.some((item1) => item1.duration === item2.id)
              );
              console.log(diff2);
              this.time_options = diff2;
            }, 500);
          } else {
            this.$message.error(ress.msg);
          }
        });
      }
    },
    close_emit() {
      this.$emit("updatemite");
    },
    query() {
      // console.log("6666");
      this.authData = [];
      if (!this.data) return;
      this.authLoading = true;
      // 获取到全部路由权限
      get_menu()
        .then((res) => {
          // console.log(res)
          if (res.code === 200) {
            this.authData = this.$util.toTreeData(res.data, "id", "pid");
            this.isCheck = true; //重点：给数节点赋值之前 先设置为true
            this.$nextTick(() => {
              //因为我是根据数据id来判断选中所以使用setCheckedKeys，具体可以查看element官网api
              this.$refs.tree.setCheckedKeys(this.form.usable_menus); //给树节点赋值
              this.isCheck = false; //重点： 赋值完成后 设置为false
            });
            // console.log(this.form.usable_menus[0])
            if (this.form.usable_menus[0] == "") {
              this.isIndeterminate = false;
              this.checkAll = false;
            } else {
              //与全选关联起来，判断是否全部选中还是部分选中
              let menuOptions = [];
              menuOptions = this.convertTreeData(this.form.usable_menus);
              let checkedCount = this.form.usable_menus.length;
              this.checkAll = checkedCount === menuOptions.length;
              this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.authData.length;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });

      // setTimeout(() => {
      //   // 获取菜单关联接口
      //   this.id = this.id || 0;
      //   getMenusCascaderApi(this.id)
      //     .then((res) => {
      //       console.log(res);
      //       if (res.code === 200) {
      //         // 这种写法为了解决默认回显父节点时子节点都选中问题
      //         this.isCheck = true; //重点：给数节点赋值之前 先设置为true
      //         this.$nextTick(() => {
      //           //因为我是根据数据id来判断选中所以使用setCheckedKeys，具体可以查看element官网api
      //           //  this.$refs.tree.setCheckedKeys(res.data.list); //给树节点赋值
      //           this.isCheck = false; //重点： 赋值完成后 设置为false
      //         });

      //         // tree默认绑定的数据
      //         // this.$refs.tree.setCheckedKeys(res.data)
      //         // 如果第一条是空的选中状态则为false
      //         if (res.data[0] == "") {
      //           this.isIndeterminate = false;
      //           this.checkAll = false;
      //         } else {
      //           //与全选关联起来，判断是否全部选中还是部分选中
      //           let menuOptions = [];
      //           menuOptions = this.convertTreeData(res.data);
      //           console.log(res);
      //           let checkedCount = res.data.list.length;
      //           // console.log(res.data.list.length)
      //           console.log(checkedCount, menuOptions);
      //           // console.log(checkedCount===menuOptions.length)
      //           this.checkAll = checkedCount === menuOptions.length;
      //           this.isIndeterminate =
      //             checkedCount > 0 && checkedCount < this.authData.length;
      //         }
      //         // 关闭加载状态
      //         this.authLoading = false;
      //       } else {
      //         this.authLoading = false;
      //         this.$message.error(res.msg);
      //       }
      //     })
      //     .catch((e) => {
      //       this.authLoading = false;
      //       this.$message.error(e.message);
      //     });
      // }, 1000);
    },

    // tree 结构转化成一维数组
    convertTreeData(menuOptions) {
      for (let i = 0; i < menuOptions.length; i++) {
        if (menuOptions[i].children != undefined) {
          const temp = menuOptions[i].children;
          delete menuOptions[i].children;
          menuOptions = menuOptions.concat(temp);
        }
      }
      return menuOptions;
    },
    handleCheckAllChange() {
      console.log(666);
      if (this.checkAll) {
        //全选
        // console.log(this.authData)
        // let data = this.authData;
        // const nodesToCheck = data.slice(1); //
        // console.log(nodesToCheck)
        this.$refs.tree.setCheckedNodes(this.authData);
        // this.$refs.tree.getCheckedKeys(this.authData)
        let ids = this.$refs.tree
          .getCheckedKeys()
          .concat(this.$refs.tree.getHalfCheckedKeys());
        // console.log(ids)
        // this.$refs.tree.setCheckedKeys(ids.concat([1, 9, 10]));
      } else {
        //取消选中
        // this.$refs.tree.setCheckedKeys([1, 9, 10]);
      }
      this.isIndeterminate = false;
    },
    save(formName) {
      // console.log(5555);
      if(this.form.rights.facilitator.switch){
        if(this.form.rights.facilitator.member_brokerage.value==""){
          this.$message({
            message: "请填写服务商返佣比例",
            type: "error",
            customClass: "zZindex",
          });
          return false;
        }
      }

      if(this.form.rights.shared.switch){
        if(this.form.rights.shared.member_brokerage.value==""||this.form.rights.shared.order_brokerage.value==""){
          this.$message({
            message: "请填写个体司机充值会员返佣比例和个体司机订单收益返佣比例",
            type: "error",
            customClass: "zZindex",
          });
          return false;
        }
      }

      var flag = true;
      // console.log(this.form.rights.raffle.switch)
      if (this.form.rights.raffle.switch) {
        if(this.form.rights.raffle.shared_count.value==""||this.form.rights.raffle.daily_online.value==""){
          this.$message({
            message: "请填写推广个体司机数量和每日在线时长",
            type: "error",
            customClass: "zZindex",
          });
          return false;
        }
        var flag2 = true;
        var nums = 0;
        for (const item of Object.entries(this.form.raffle)) {
          // console.log(item[1].money, item[1].odds);
          if (item[1].money == "" && item[1].money != 0) {
            // console.log(item[1].odds);
            flag2 = false;
            break;
          } else {
            console.log(item[1].odds);
            nums += parseFloat(item[1].odds);
          }
        }
        console.log(nums);
        if (!flag2 || nums != 100) {
          // console.log(1111);
          this.$message({
            message: "请填写抽奖配置,概率加起来必须100%",
            type: "error",
            customClass: "zZindex",
          });
          return false;
        }
      }

      for (let i = 0; i < this.form.cost.length; i++) {
        if (
          this.form.cost[i].money == "" &&
          this.form.cost[i].weight == "" &&
          this.form.cost[i].duration == ""
        ) {
          flag = false;
          break;
        }
      }

      if (!flag) {
        // console.log(1111);
        this.$message({
          message: "请填写会员费配置",
          type: "error",
          customClass: "zZindex",
        });
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id != 0) {
            let ids = this.$refs.tree
              .getCheckedKeys()
              .concat(this.$refs.tree.getHalfCheckedKeys());

            if(ids.length == 0){
              this.$message.error('请选择可用功能');
              return false
            }else {
                this.form.usable_menus = ids;
                huiyuan_xiugai(this.id, this.form).then((res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.$emit("updatemite");
                    } else {
                        this.loading = false;
                        this.$message.error(res.msg);
                    }
                });
            }
          } else {
            let ids = this.$refs.tree
              .getCheckedKeys()
              .concat(this.$refs.tree.getHalfCheckedKeys());
              if(ids.length == 0){
                  this.$message.error('请选择可用功能');
                  return false
              }else {
                  this.form.usable_menus = ids;
                  console.log(this.form);
                  huiyuan_add(this.form).then((res) => {
                      if (res.code === 200) {
                          this.$message.success(res.msg);
                          this.$emit("updatemite");
                      } else {
                          this.loading = false;
                          this.$message.error(res.msg);
                      }
                  });
              }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });


      //   this.loading = true;

      //   relevancy_menus(this.data.id, data)
      //     .then((res) => {
      //       console.log(res);
      //       if (res.code === 200) {
      //         this.$message.success(res.msg);
      //         this.loading = false;
      //         this.updateVisible(false);
      //       } else {
      //         this.loading = false;
      //         this.$message.error(res.msg);
      //       }
      //     })
      //     .catch((e) => {
      //       this.loading = false;
      //       this.$message.error(e.message);
      //     });
    },

    InputE(item){
      const exp = /^\d+(\.\d{1,2})?$/;
      if(!exp.test(item.odds)){
        this.$message({
          message: "请输入正确格式的概率",
          type: "error",
        });
        item.odds = "";
        return false;
      }
    },

    InputE1(item){
      const exp = /^\d+(\.\d{1,2})?$/;
      if(!exp.test(item.money)){
        this.$message({
          message: "请输入正确格式的金额",
          type: "error",
        });
        item.money = "";
        return false;
      }
    },

    InputE2(item){
      const exp = /^\d+(\.\d{1,2})?$/;
      if(!exp.test(item.value)){
        this.$message({
          message: "请输入正确格式小数点后只能保留两位",
          type: "error",
        });
        item.value = "";
        return false;
      }else {
        if(item.value>100 || item.value<0){
          item.value = 100;
        }
      }
    },

    InputE3(item){
      const exp = /^\d+(\.\d{1,2})?$/;
      if(!exp.test(item.value)){
        this.$message({
          message: "请输入正确格式小数点后只能保留两位",
          type: "error",
        });
        item.value = "";
        return false;
      }else {
        if(item.value>100 || item.value<0){
          item.value = 100;
        }
      }
    },

    InputE4(item){
      const exp = /^\d+(\.\d{1,2})?$/;
      if(!exp.test(item.value)){
        this.$message({
          message: "请输入正确格式小数点后只能保留两位",
          type: "error",
        });
        item.value = "";
        return false;
      }else{
        if(item.value>100 || item.value<0){
          item.value = 100;
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.flex_choujiang {
  display: flex;
  border: 1px solid #e8eaec;
}
.border-right-left {
  border-left: 1px solid #e8eaec;
  border-right: 1px solid #e8eaec;
}
.border-top-border {
  border-top: 1px solid #e8eaec;
  // border-bottom:1px solid   #e8eaec;
}
.w-30 {
  width: 20%;
}
.flex_start {
  display: flex;
  justify-content: flex-start;
}
::v-deep .el-form-item__label {
  display: flex;
}
::v-deep .el-row {
  margin-bottom: 10px;
}
/deep/ .zZindex {
  z-index: 3000 !important;
}
</style>
