import request from '@/utils/request'

// =============================
// TODO: 角色管理国度
// =============================
/**
 * [身份管理]获取身份列表
 * @param params
 * @returns {*}
 */
export function getRolesListApi (params) {
  return request({
    url: '/system/roles',
    method: 'get',
    params
  })
}

/**
 * [身份管理]权限列表
 * @returns {*}
 */
export function getMenusJurisdictionListApi () {
  return request({
    url: '/system/roles/roles_jurisdiction',
    method: 'get'
  })
}

/**
 * [身份管理]保存新增权限
 * @param data
 * @returns {*}
 */
export function saveRolesCreateApi (data) {
  return request({
    url: `/system/roles/${data.id}`,
    method: 'post',
    data
  })
}

/**
 * [身份管理]获取角色身份信息
 * @param id
 * @returns {*}
 */
export function getRolesInfoApi (id) {
  return request({
    url: `/system/roles/${id}/edit`,
    method: 'get'
  })
}

// =============================
// TODO: 菜单权限管理国度
// =============================
/**
 * 获取菜单权限列表接口
 * @param params
 * @returns {*}
 */
export function getMenusListApi (params) {
  return request({
    url: '/system/facilitator_menus',
    method: 'get',
    params
  })
}

/**
 * 获取菜单权限列表接口 超管
 * @param params
 * @returns {*}
 */
export function getsuperman_menus (params) {
  return request({
    url: '/system/superman_menus',
    method: 'get',
    params
  })
}


/**
 * 获取菜单权限详情信息接口
 * @param id
 * @returns {*}
 */
export function getMenusInfoApi (id) {
  return request({
    url: `/system/facilitator_menus/${id}`,
    method: 'get'
  })
}


/**
 * 获取菜单权限详情信息接口 超管
 * @param id
 * @returns {*}
 */
export function getsuperman_menusDetails (id) {
  return request({
    url: `/system/superman_menus/${id}`,
    method: 'get'
  })
}

/**
 * 新增菜单权限接口
 * @param data
 * @returns {*}
 */
export function saveMenusInfoSaveApi (data) {
  return request({
    url: '/system/facilitator_menus',
    method: 'post',
    data
  })
}

/**
 * 新增菜单权限接口 超管
 * @param data
 * @returns {*}
 */
export function Addsuperman_menus (data) {
  return request({
    url: '/system/superman_menus',
    method: 'post',
    data
  })
}

/**
 * 修改菜单权限接口
 * @param data
 * @returns {*}
 */
export function updateMenusInfoApi (data) {
  return request({
    url: `/system/facilitator_menus/${data.id}`,
    method: 'put',
    data
  })
}

/**
 * 修改菜单权限接口 超管
 * @param data
 * @returns {*}
 */
export function editsuperman_menus (data) {
  return request({
    url: `/system/superman_menus/${data.id}`,
    method: 'put',
    data
  })
}

/**
 * 破坏菜单结构接口
 * @param data
 * @returns {*}
 */
export function menudestroy (id,data) {
  return request({
    url: `/system/facilitator_menus/${id}/destroy`,
    method: 'post',
    data
  })
}

/**
 * 破坏菜单结构接口 超管
 * @param data
 * @returns {*}
 */
export function menudestroysuperman_menus (id,data) {
  return request({
    url: `/system/superman_menus/${id}/destroy`,
    method: 'get',
    data
  })
}


/**
 * 获取联级菜单数据
 * @param id
 * @returns {*}
 */
export function getMenusCascaderApi (id) {
  return request({
    url: `/system/facilitator_menus/${id}/cascader`,
    method: 'get'
  })
}
/**
 * 获取联级菜单数据 超管
 * @param id
 * @returns {*}
 */
export function superman_menuschaoji (id) {
  return request({
    url: `/system/superman_menus/${id}/cascader`,
    method: 'get'
  })
}

// =============================
// TODO: 语言列表管理国度
// =============================
/**
 * [多语言]语言类型列表
 * @param params
 * @returns {*}
 */
export function getLangTypeListApi (params) {
  return request({
    url: 'system/lang/type',
    method: 'get',
    params
  })
}

/**
 * [多语言]语言类型新增编辑
 * @param id
 */
export function getLangTypeFormApi (id) {
  return request({
    url: `system/lang/type/form/${id}`,
    method: 'get'
  })
}

/**
 * [多语言]语言类型状态修改
 * @param id
 * @param status
 * @returns {*}
 */
export function setLangTypeStatusApi (id, status) {
  return request({
    url: `system/lang/type/status/${id}/${status}`,
    method: 'put'
  })
}

/**
 * [多语言]语言列表
 * @param params
 * @returns {*}
 */
export function getLangCodeListApi (params) {
  return request({
    url: 'system/lang/code',
    method: 'get',
    params
  })
}

/**
 * [多语言]获取语言信息
 * @param params
 * @returns {*}
 */
export function getLangCodeInfoApi (params) {
  return request({
    url: 'system/lang/code/info',
    method: 'get',
    params
  })
}

/**
 * [多语言]保存新增语言
 * @param data
 * @returns {*}
 */
export function saveLangCodeApi (data) {
  return request({
    url: 'system/lang/code/save',
    method: 'post',
    data
  })
}

/**
 * 一键翻译
 * @param data
 * @returns {*}
 */
export function getLangCodeTranslateApi (data) {
  return request({
    url: 'system/lang/code/translate',
    method: 'post',
    data
  })
}

/**
 * [多语言] 国家列表
 * @param params
 * @returns {*}
 */
export function getLangCountryListApi (params) {
  return request({
    url: 'system/lang/country/list',
    method: 'get',
    params
  })
}

/**
 * [多语言] 添加语言地区表单
 * @param id
 * @returns {*}
 */
export function getLangCountryFormApi (id) {
  return request({
    url: `system/lang/country/form/${id}`,
    method: 'get'
  })
}

// =============================
// TODO: 组合数据配置管理国度
// =============================
/**
 * 组合数据--列表
 * @param params
 * @returns {*}
 */
export function getGroupListApi (params) {
  return request({
    url: 'setting/group',
    method: 'get',
    params
  })
}

/**
 * 数据配置
 * @returns {*}
 */
export function getGroupAllApi () {
  return request({
    url: 'setting/group_all',
    method: 'get'
  })
}

/**
 * 组合数据列表
 * @param id
 * @param url
 * @returns {*}
 */
export function getGroupDataListApi (id, url) {
  return request({
    url: url,
    method: 'get',
    params: id
  })
}

/**
 * [组合数据列表]编辑表单
 * @param data
 * @param url
 * @returns {*}
 */
export function getGroupDataHeaderApi (data, url) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}

/**
 * [组合数据列表]新增表单
 * @param id
 * @param url
 * @returns {*}
 */
export function createGroupDataApi (id, url) {
  return request({
    url: url,
    method: 'get',
    params: id
  })
}

/**
 * [组合数据列表]修改状态
 * @param url
 * @returns {*}
 */
export function setGroupDataStatusApi (url) {
  return request({
    url: url,
    method: 'PUT'
  })
}

/**
 * [组合数据列表]编辑表单
 * @param data
 * @param url
 * @returns {*}
 */
export function getGroupDataEditApi (data, url) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}

/**
 * [组合数据]新增
 * @param data
 * @returns {*}
 */
export function createGroupApi (data) {
  return request({
    url: data.url,
    method: data.method,
    data: data.datas
  })
}

/**
 * [组合数据]详情
 * @param id
 * @returns {*}
 */
export function getGroupInfoApi (id) {
  return request({
    url: `setting/group/${id}`,
    method: 'get'
  })
}

/**
 * 数据配置保存
 * @param data
 * @returns {*}
 */
export function groupSaveApi (data) {
  return request({
    url: 'setting/group_data/save_all',
    method: 'POST',
    data
  })
}

/**
 * 引导页数据配置保存
 * @param data
 * @returns {*}
 */
export function getOpenAdvSaveApi (data) {
  return request({
    url: 'diy/open_adv/add',
    method: 'POST',
    data
  })
}

/**
 * 获取协议
 * @param type
 * @returns {*}
 */
export function getAgreementsApi (type) {
  return request({
    url: `setting/get_agreement/${type}`,
    method: 'get'
  })
}

/**
 * 设置隐私协议
 * @param data
 * @returns {*}
 */
export function setAgreementApi (data) {
  return request({
    url: 'setting/set_user_agreement',
    method: 'post',
    data
  })
}

// =============================
// TODO: 维护管理国度
// =============================
/**
 * [系统日志]搜索条件
 * @param data
 * @returns {AxiosPromise}
 */
export function searchAdminApi (data) {
  return request({
    url: 'system/log/search_admin',
    method: 'GET'
  })
}

/**
 * [系统日志]搜索条件
 * @param params
 * @returns {AxiosPromise}
 */
export function getSystemListApi (params) {
  return request({
    url: 'system/log',
    method: 'GET',
    params
  })
}


/**
 * [系统] 字典管理
 * @param params
 * @returns {AxiosPromise}
 */
// /system/dictionary/list
export function getDictionary (params) {
  return request({
    url: 'system/dictionary/list',
    method: 'GET',
    params
  })
}
/**
 * [系统] 新增字典接口
 * @param params
 * @returns {*}
 */
// /system/dictionary/list
export function AddDictcategory (data) {
  return request({
    url: `system/dictionary/list`,
    method: 'post',
    data
  })
}

/**
 * [系统] 编辑字典接口
 * @param params
 * @returns {*}
 */
// /system/dictionary/list/{id}
export function EditDictcategory (id,data) {
  return request({
    url: `system/dictionary/list/${id}`,
    method: 'put',
    data
  })
}

/**
 * [系统] 删除字典接口
 * @returns {*}
 */
// /system/dictionary/list/{1}
export function DelDictcategory (id) {
  return request({
    url: `system/dictionary/list/${id}`,
    method: 'delete',
  })
}

/**
 * [系统] 获取字典类目信息接口
 * @param params
 * @returns {*}
 */
// /system/dictionary/list/{id}
// /system/dictionary/list/{id}
// /system/dictionary/list/{pid}/children
export function getDictcategory (id,params) {
  return request({
    url: `system/dictionary/list/${id}/children`,
    method: 'GET',
    params
  })
}


/**
 * [系统] 获取字典项接口
 * @param params
 * @returns {*}
 */
// /system/dictionary/data
export function getDictdeta (id,params) {
  return request({
    url: `system/dictionary/data?dict_id=${id}`,
    method: 'GET',
    params
  })
}
/**
 * [系统] 新增字典项接口
 * @returns {*}
 */
// /system/dictionary/data
export function AddDictdata (data) {
  return request({
    url: `system/dictionary/data`,
    method: 'post',
    data
  })
}
/**
 * [系统] 编辑字典项接口
 * @returns {*}
 */
// /system/dictionary/data/{id}
export function EditDictdata (id,data) {
  return request({
    url: `system/dictionary/data/${id}`,
    method: 'put',
    data
  })
}
/**
 * [系统] 删除字典项接口
 * @returns {*}
 */
// /system/dictionary/data/{id}
export function DelDictdata (id) {
  return request({
    url: `system/dictionary/data/${id}`,
    method: 'delete',
  })
}

/**
 * [系统] 获取格式化后的字典接口
 * @param params
 * @returns {AxiosPromise}
 */
// /system/dictionary/list/{pid}/children
export function getDictdata_childreny (params) {
  return request({
    url: '/common/dictionary_data',
    method: 'GET',
    params
  })
}

/**
 * [系统] 文件上传
 * @returns {*}
 */
export function upload_files (data) {
  return request({
    url: `common/upload/`,
    method: 'post',
    data
  })
}


/**
 * [系统] 服务项目配置
 * @param params
 * @returns {*}
 */
export function getservices_available (params) {
  return request({
    url: 'system/services_available',
    method: 'GET',
    params
  })
}
/**
 * [系统] 服务项目配置 删除
 * @returns {*}
 */
export function Delservices_available (id) {
  return request({
    url: `system/services_available/${id}`,
    method: 'delete',
  })
}
/**
 * [系统] 服务项目配置 新增
 * @returns {*}
 */
export function Addservices_available (data) {
  return request({
    url: `system/services_available`,
    method: 'post',
    data
  })
}
/**
 * [系统] 服务项目配置 修改
 * @returns {*}
 */
export function Editservices_available (id,data) {
  return request({
    url: `system/services_available/${id}`,
    method: 'put',
    data
  })
}

/**
 * 系统日志操作记录接口
 * @returns {*}
 */
export function getlog (params) {
  return request({
    url: 'system/operate/log',
    method: 'get',
    params
  })
}


/**
 * 获取自定义表单列表接口
 * @returns {*}
 */
export function diyform (params) {
  return request({
    url: 'diy/form',
    method: 'get',
    params
  })
}
/**
 * [系统] 获取自定义表单组件列表接口
 * @returns {*}
 */
export function form_assistlist (id,data) {
  return request({
    url: `diy/form_assist/list/${id}`,
    method: 'get',
    data
  })
}
/**
 * [系统] 获取自定义表单组件详情接口
 * @returns {*}
 */
export function form_assistread (id,data) {
  return request({
    url: `diy/form_assist/read/${id}`,
    method: 'get',
    data
  })
}
/**
 * 部分可用服务商列表接口
 * @returns {*}
 */
export function facilitator_tables (params) {
  return request({
    url: 'diy/form_assist/facilitator_tables',
    method: 'get',
    params
  })
}

/**
 * [系统] 组件设置权限接口
 * @returns {*}
 */
export function form_assistrules (id,data) {
  return request({
    url: `diy/form_assist/rules/${id}`,
    method: 'post',
    data
  })
}

/**
 * [系统] 更新自定义表单接口 修改
 * @returns {*}
 */
export function form_assist (id,data) {
  return request({
    url: `diy/form/${id}`,
    method: 'put',
    data
  })
}

/**
 * [系统] 获取自定义表单详情接口
 * @returns {*}
 */
export function getForm (id,data) {
  return request({
    url: `diy/form/${id}`,
    method: 'get',
    data
  })
}

/**
 * [系统] 获取自定义表单元素接口
 * @returns {*}
 */
export function get_form (id,data) {
  return request({
    url: `diy/get_form`,
    method: 'get',
    data
  })
}


/**
 * [系统] 获取店铺装修设置接口
 * @returns {*}
 */
export function get_shop_setup (data) {
  return request({
    url: `common/get_shop_setup`,
    method: 'get',
    data
  })
}
/**
 * [系统] 店铺装修设置接口
 * @returns {*}
 */
export function put_shop_setup (data) {
  return request({
    url: `common/put_shop_setup`,
    method: 'post',
    data
  })
}

//城市管理

//获取城市管理列表
export function get_citylist (params) {
  return request({
    url: `system/city/list`,
    method: 'get',
    params
  })
}

//清除城市列表缓存
export function get_clean () {
  return request({
    url: `system/city/clean_cache`,
    method: 'get',
  })
}

//城市管理删除
export function del_city (params) {
  return request({
    url: `system/city/delete`,
    method: 'get',
    params
  })
}

//城市管理添加
export function add_city (data) {
  return request({
    url: `system/city/create`,
    method: 'post',
    data
  })
}

//保司管理

//获得保司管理列表
export function get_insurerlist (params) {
  return request({
    url: `/system/insurer/list`,
    method: 'get',
    params
  })
}
//获得保司管理详情--正式、测试
export function get_insurer_Info (params) {
  return request({
    url: `/system/insurer/get_insurer_Info`,
    method: 'get',
    params
  })
}
//保司管理正式--测试 确定修改
export function update_insurer_Info (data) {
  return request({
    url: `system/insurer/update_insurer_Info`,
    method: 'post',
    data
  })
}



//客户Api日志
//获取列表
export function api_order_log (params) {
  return request({
    url: `/system/api_order_log/list`,
    method: 'get',
    params
  })
}
//获取详情
export function api_order_log_info (id,params) {
  return request({
    url: `/system/api_order_log/info/${id}`,
    method: 'get',
    params
  })
}



//客户回传配置
//获得客户回传配置列表
export function get_postbacklist (params) {
  return request({
    url: `/system/postback/list`,
    method: 'get',
    params
  })
}
//获得客户
export function get_postback_client (firmId,params) {
  return request({
    url: `/system/postback/client/${firmId}`,
    method: 'get',
    params
  })
}
//客户回传配置 新增
export function add_postbacksave (data) {
  return request({
    url: `system/postback/save`,
    method: 'post',
    data
  })
}
//客户回传配置 编辑
export function edit_postbacksave (id,data) {
  return request({
    url: `system/postback/put/${id}`,
    method: 'put',
    data
  })
}

//获得附近城市列表
export function city_list (params) {
  return request({
    url: `/common/city/list`,
    method: 'get',
    params
  })
}
export function city_code_list () {
  return request({
    url: `/common/city/code_list`,
    method: 'get',
  })
}
//新增附近的城市
export function city_create (data) {
  return request({
    url: `/common/city/create`,
    method: 'post',
    data
  })
}
//删除附近的城市
export function city_del (id) {
  return request({
    url: `/common/city/del/${id}`,
    method: 'delete',
  })
}
