<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { on, off } from 'view-design/src/utils/dom'
import { setMatchMedia } from 'view-design/src/utils/assist'
import { Local } from '@/utils/storage.js'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import store from './store'

setMatchMedia()

export default {
  data(){
    return{
      // 是否登录
      islogin: false,
    }
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    //
  },
  watch: {
    // $route (to, from) {
    //   const path = to.path
    //
    //   const menuSider = this.$store.state.menus.menusName
    //   const headerName = getHeaderName(to, menuSider)
    //   if (headerName !== null) {
    //     this.$store.commit('menus/setActivePath', path)
    //
    //     let openNameList = getMenuopen(to, menuSider)
    //     this.$store.commit('menus/setOpenMenus', openNameList)
    //
    //     const openNames = getSiderSubmenu(to, menuSider)
    //     this.$store.commit('menus/setOpenNames', openNames)
    //     // 设置顶栏菜单 后台添加一个接口，设置顶部菜单
    //     const headerSider = getHeaderSider(menuSider)
    //     this.$store.commit('menus/setHeaderData', headerSider)
    //     // 指定当前侧边栏隶属顶部菜单名称。如果你没有使用顶部菜单，则设置为默认的（一般为 home）名称即可
    //     this.$store.commit('menus/setHeaderName', headerName)
    //     // 获取侧边栏菜单
    //     const filterMenuSider = getMenuSider(menuSider, headerName)
    //     // 指定当前显示的侧边菜单
    //     this.$store.commit('menus/setOpenMenuName', filterMenuSider[0].meta.title)
    //     this.$store.commit('menus/setSiderData', filterMenuSider[0]?.children || [])
    //   } else {
    //     // 子路由给默认 如果你没有使用顶部菜单，则设置为默认的（一般为 home）名称即可
    //     if (to.name == 'home_index') {
    //       this.$store.commit('menus/setHeaderName', config.routePre + '/home/')
    //       this.$store.commit('menus/setSiderData', [])
    //     }
    //     // 指定当前显示的侧边菜单
    //   }
    // }
  },

  methods: {
    //获取当前经度纬度
    getLocation() {
      navigator.geolocation.getCurrentPosition(position => {
        // console.log(position)
        let location = {
          longitude:position.coords.longitude,
          latitude:position.coords.latitude
        }
        localStorage.setItem('location', JSON.stringify(location))
      })
    },

    ...mapMutations('layout', ['setDevice']),
    // 监听窗口大小
    handleWindowResize () {
      this.handleMatchMedia()
    },
    // 监听机型
    handleMatchMedia () {
      const matchMedia = window.matchMedia
      if (matchMedia('(max-width: 600px)').matches) {
        // var deviceWidth = document.documentElement.clientWidth || window.innerWidth
        let css = 'calc(100vw/7.5)'
        document.documentElement.style.fontSize = css
        this.setDevice('Mobile')
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet')
      } else {
        this.setDevice('Desktop')
      }
    },
    // 刷新
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
	  // 获取缓存中的布局配置
	  getLayoutThemeConfig () {
		  if (Local.get('themeConfigPrev')) {
			  this.$store.dispatch('themeConfig/setThemeConfig', Local.get('themeConfigPrev'))
			  document.documentElement.style.cssText = Local.get('themeConfigStyle')
		  } else {
			  Local.set('themeConfigPrev', this.$store.state.themeConfig.themeConfig)
		  }
	  },
	  // 布局配置弹窗打开
	  // openSettingsDrawer () {
		//   this.bus.$on('openSettingsDrawer', () => {
		// 	  this.$refs.setingsRef.openDrawer()
		//   })
	  // },

    // 点击消息忽略按钮
    losesightof(){
      this.showmessage = false;
    },

    // 监听本地存储的数据变化方法
    showHidden(){
      // 获取是否显示电话弹窗
      let show = JSON.parse(localStorage.getItem('showPhone'));
      // 根据数据判断是否打开拨打电话的弹窗
      if(show.showPhone == true){
        this.display = true;
      }else {
        this.display = false;
      }
    },

    //websocket消息跳转
    To(){
      if(this.websocketType == 'NEW_ORDER'){
        this.showmessage = false;
        this.$router.push('/orderModule/list');
      }else if(this.websocketType == 'AGING'){
        // 时效
        this.showmessage = false;
        this.$router.push('/orderModule/myMission');
      }else if(this.websocketType == 'ABNORMAL'){
        // 异常通知
        this.showmessage = false;
        this.$router.push('/orderModule/abnormalOrder');
      }else if(this.websocketType == 'COMPLAINT'){
        // 投诉
        this.showmessage = false;
        this.$router.push('/orderModule/toBeCompleted');
      }else if(this.websocketType == 'REMINDER'){
        // 催单
        this.showmessage = false;
        this.$router.push('/orderModule/myMission');
      }else if(this.websocketType == 'DELAY_ORDER'){
        // 订单回滚
        this.showmessage = false;
        this.$router.push('/orderModule/list');
      }
    },
  },

  mounted () {
    //调用获取经度纬度
    // this.getLocation();
    on(window, 'resize', this.handleWindowResize)
    this.handleMatchMedia()
    // this.openSettingsDrawer()
    // this.getLayoutThemeConfig()

    // 继承下vuex中的菜单栏背景颜色配置
    console.log(store,'数据')
    // 侧边菜单栏颜色
    document.documentElement.style.setProperty(`--menu-dark-background`, store.state.theme.sideStyleBackground);
    // 顶栏颜色
    document.documentElement.style.setProperty(`--header-dark-background`, store.state.theme.headerStyleBackground);
    // 二级菜单颜色
    document.documentElement.style.setProperty(`--content-image-filter`, store.state.theme.erjisideStyleBackground);
  },

  beforeDestroy () {
    off(window, 'resize', this.handleWindowResize)
  },
  destroyed () {
    // this.bus.$off('openSettingsDrawer')
  },
  updated() {
    // 判断是否登录
    if(getCookies(Config.tokenStoreName) == false){
      this.islogin = false;
    }else {
      this.islogin = true
    }
  },
}
</script>

<style>
.el-time-spinner__item{
  height: 32px !important;
  line-height: 17px !important;
}
</style>

<style lang="scss">
// @import "~@/layout/theme/variate";
* {
  box-sizing: content-box;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  font-family: PingFang SC, Arial, Microsoft YaHei, sans-serif;
}

// iview 时间范围选择统一样式
.ivu-input{
  height: 36px !important;
}

.ele-text-center{
  display: none !important;
}
// 全局css 加上以下代码，可以隐藏上下箭头

// 取消input的上下箭头
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;

}

input::-webkit-outer-spin-button{
  -webkit-appearance: none !important;

}

input[type="number"]{
  -moz-appearance: textfield;

}

//#app {
//  font-family: Avenir, Helvetica, Arial, sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  text-align: center;
//  color: #2c3e50;
//}
//
//#nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}

  // 自定义样式
.title:after{
  content: '';
  clear: both;
  display: block;
}
.title{
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  //margin-bottom: 20px;
  .title_t{
    font-size: 24px;
    font-weight: 500;
    color: #707070;
    margin-bottom: 20px;
    float: left;
  }
  .title_r{
    // 鼠标变小手
    cursor: pointer;
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #e4e5e9;
    border: 1px solid #e4e5e9;
    border-radius: 6px;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #3f4157 !important;
    text-align: center;
    float: right;
  }
}
.card{
  padding: 0!important;
  .card_t{
    display: flex;
    align-items: center;
  }
  .card_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .card_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
  .card_b{
    margin-top: 15px;
  }
}
.card_bottom{
  margin-top: 80px;
  text-align: center;
}

.el-dialog{
  border-radius: 20px !important;
}

//所有输入框的边框颜色
.el-input__inner{
  //border-color: #ededf5;
}
//.el-input__inner:hover{
//  border-color: #ededf5 !important;
//}

//富文本编辑器单独样式
.w-e-bar-item{
  .title{
    font-size: 14px !important;
  }
}

.el-scrollbar__bar.is-vertical{
  right: 0;
}


//大屏样式
.active-ring-name{
  display: none !important;
}
.unit-label{

}

.sel{
  .el-input--mini .el-input__inner{
    height: 36px !important;
  }
}
.xiaadan{
  .el-dialog__body{
    padding-top: 0!important;
  }
}


//消息提醒弹窗
.messageinform{
  z-index: 9999;
  cursor: pointer;
  width: 300px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  //background: #FFFFFF;
  background-color: rgba(0,0,0,0.5);
  color: #303133;
  transition: .3s;
  position: fixed;
  right: 20px;
  bottom: 20px;
  .messageinformicon{
    width: 70px;
    padding: 10px;
    padding-bottom: 7px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
    background: #fff;
    border-radius: 50%;
    text-align: center;
    margin: auto;
    margin-top: 10px;
  }
}


//电话弹窗
.phone{
  z-index: 9999;
  cursor: pointer;
  width: 300px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  //background: #FFFFFF;
  background-color: rgba(0,0,0,0.5);
  color: #303133;
  transition: .3s;
  position: fixed;
  right: 20px;
  bottom: 50%;
}



.amap-logo {
  display: none !important;
}
.amap-copyright {
  display: none !important;
}


.messageIndex {
  z-index: 3000 !important; // 设置的值一定要比2007大，因为dialog的层级是2007
}

/* 确保v-viewer的modal总是在最上层 */
.v-viewer-container .v-viewer-canvas {
  z-index: 10000; /* 设置一个足够高的z-index值 */
}
/* 确保v-viewer的overlay总是在最上层 */
.v-viewer-container .v-viewer-mask {
  z-index: 10000; /* 设置一个足够高的z-index值 */
}

//表格显示省略号
.shengluehao{
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .ele-admin-sidebar-nav .ele-admin-sidebar-nav-menu > .el-menu.el-menu--collapse > .el-menu-item > a span {

// }
// .ele-admin-sidebar-nav ::v-deep .ele-admin-sidebar-nav-menu > .el-menu.el-menu--collapse > .el-menu-item > a span {
//   /* 修改子组件中的样式 */
//     max-height: 1.06486rem !important;
//     color: red !important;
// }
</style>
